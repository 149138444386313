<template>
    <div id="display-device-battery-level-component">
        <div class="d-flex align-center">
            <template v-if="battery">
                <v-icon class="mr-1 rotate-90" dense>
                    {{
                        battery > 80 ? 'mdi-battery' : battery > 50 ? 'mdi-battery-80' : battery > 20 ? 'mdi-battery-50' : 'mdi-battery-20'
                    }}
                </v-icon>
                <span class="font-weight-medium text-no-wrap">{{ battery }}%</span>
            </template>
            <template v-else>
                <v-icon dense> mdi-battery-unknown</v-icon>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DisplayDeviceBatteryLevelComponent',
    props: {
        battery: {
            type: Number,
            required: true,
        },
    },
}
</script>
