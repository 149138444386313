<template>
    <div id="map-position-history-widget-component">
        <widget-wrapper-component :title="$t('map_position_history_widget_component.list_positions')">
            <template v-slot:default>
                <template v-if="device">
                    <date-range-picker-component
                        :date_range="date_range"
                        :loading="fetching_coordinate"
                        class="mb-4"
                        v-on:update="updateRangeDate"
                    />

                    <div class="display-flex-align-center mb-2 font-12 font-color-normal">
                        <span class="mr-2">
                            {{ $t('map_position_history_widget_component.start_journey') }}
                        </span>

                        <svg class="my-auto" height="14" viewBox="0 0 300 40" xmlns="http://www.w3.org/2000/svg">
                            <rect fill="#1DB9F3" fill-opacity="0.6" height="32" width="267.396" x="17.592" y="4" />
                            <ellipse cx="20" cy="20" fill="#EA4335" rx="20" ry="20" />
                            <ellipse cx="100" cy="20" fill="#1DB9F3" rx="20" ry="20" />
                            <ellipse cx="200" cy="20" fill="#1DB9F3" rx="20" ry="20" />
                            <ellipse cx="280" cy="20" fill="#65B265" rx="20" ry="20" />
                        </svg>

                        <span class="ml-2">
                            {{ $t('map_position_history_widget_component.end_journey') }}
                        </span>
                    </div>

                    <gmap-map
                        ref="gmap"
                        :center="{ lat: 47, lng: 5 }"
                        :zoom="20"
                        class="google-map-container"
                        @click="mapFitBounds($refs.gmap, markers)"
                    >
                        <template v-for="(marker, index) in markers">
                            <gmap-marker
                                :key="index"
                                :icon="{
                                    url: `/images/map_marker/${marker.image}.svg`,
                                    scaledSize: {
                                        width: marker.image !== 'polyline_marker' ? 12 : 8,
                                        height: marker.image !== 'polyline_marker' ? 12 : 8,
                                    },
                                }"
                                :position="{ lat: marker.latitude, lng: marker.longitude }"
                                @click="openMarkerByIndex(index)"
                            >
                                <gmap-info-window :opened="opened_marker_index === index" @closeclick="opened_marker_index = null">
                                    <div class="text-skin-default">{{ marker.date_releve }}</div>
                                </gmap-info-window>
                            </gmap-marker>
                        </template>

                        <gmap-polygon
                            :options="{ geodesic: true, strokeColor: '#1DB9F3', strokeOpacity: 0.6, strokeWeight: 6 }"
                            :path="path"
                        />
                    </gmap-map>
                </template>

                <template v-else>
                    {{ $t('map_position_history_widget_component.no_geolocation_sensor') }}
                </template>
            </template>
        </widget-wrapper-component>
    </div>
</template>

<script>
import WidgetWrapperComponent from '@/components/Equipment/Generic/Widget/WidgetWrapperComponent.vue'
import DateRangePickerComponent from '@/components/Global/DateRangePickerComponent.vue'
import DeviceRepository from '@/repositories/DeviceRepository'

export default {
    name: 'MapPositionHistoryWidgetComponent',
    components: { DateRangePickerComponent, WidgetWrapperComponent },
    props: {
        device: {
            required: true,
        },
    },
    data() {
        return {
            date_range: {
                startDate: this.moment().clone().startOf('day').subtract(12, 'month').toDate(),
                endDate: this.moment().toDate(),
            },
            fetching_coordinate: true,

            path: [],
            markers: [],
            opened_marker_index: null,
        }
    },
    methods: {
        updateRangeDate(date_range) {
            this.date_range = date_range
            this.fetchDeviceTraitedData()
        },

        fetchDeviceTraitedData() {
            this.fetching_coordinate = true

            DeviceRepository.getCoordinatesByDevice(
                this.device.id_device,
                new URLSearchParams({
                    date_start: this.setDateForRequest(this.date_range.startDate),
                    date_end: this.setDateForRequest(this.date_range.endDate),
                })
            )
                .then((success) => {
                    this.buildPolylinePath(success.data.data.data)
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.fetching_coordinate = false
                    this.mapFitBounds(this.$refs.gmap, this.markers)
                })
        },

        buildPolylinePath(device_traited_data) {
            this.path = []
            this.markers = []

            device_traited_data.forEach((dtd) => {
                if (dtd.lat && dtd.lng) {
                    this.path.push({ lat: parseFloat(dtd.lat), lng: parseFloat(dtd.lng) })
                    this.markers.push({
                        latitude: parseFloat(dtd.lat),
                        longitude: parseFloat(dtd.lng),
                        image: 'polyline_marker',
                        date_releve: dtd.date_releve,
                    })
                }
            })

            if (this.markers.length >= 2) {
                this.markers[0].image = 'start_polyline_marker'
                this.markers[this.markers.length - 1].image = 'end_polyline_marker'
            }
        },

        mapFitBounds(myMapRef, markers) {
            const google = window.google

            //window.google possibly undefined onMounted
            if (google) {
                let bounds = new google.maps.LatLngBounds()

                markers.forEach((marker) => {
                    bounds.extend(new google.maps.LatLng(marker.latitude, marker.longitude))
                })

                if (myMapRef) {
                    myMapRef.fitBounds(bounds)
                }
            } else {
                //Retry if window.google undefined
                setTimeout(() => {
                    this.mapFitBounds(myMapRef, markers)
                }, 250)
            }
        },

        openMarkerByIndex(index) {
            this.opened_marker_index = index
        },
    },
    created() {
        if (this.device) {
            this.fetchDeviceTraitedData()
        }
    },
}
</script>
