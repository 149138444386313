<template>
    <div id="dialog-edit-widget-chart-component">
        <v-dialog v-model="dialog_edit_chart" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="text-uppercase font-weight-bold">
                        {{ $t('dialog_edit_widget_chart_component.configure_widget_chart') }}
                    </span>
                </v-card-title>

                <v-card-text class="pb-0">
                    <p class="font-12">{{ $t('dialog_edit_widget_chart_component.choose_data_display') }}</p>
                    <v-select
                        v-model="chart_configuration_local.metric"
                        :items="metrics"
                        :label="$t('dialog_edit_widget_chart_component.metric')"
                        :menu-props="{ bottom: true, offsetY: true }"
                        class="mb-2"
                        filled
                        hide-details
                        item-text="libelle"
                        item-value="id"
                        return-object
                    />

                    <v-select
                        v-model="chart_configuration_local.unit"
                        :items="units"
                        :label="$t('dialog_edit_widget_chart_component.unit_measure')"
                        :menu-props="{ bottom: true, offsetY: true }"
                        filled
                        hide-details
                        item-text="libelle"
                        item-value="id"
                        return-object
                    />

                    <v-expand-transition>
                        <div v-if="chart_configuration_local.metric">
                            <v-switch
                                v-model="chart_configuration_local.show_alerts"
                                :label="$t('dialog_edit_widget_chart_component.display_alert')"
                                hide-details
                                inset
                            />

                            <v-switch
                                v-model="chart_configuration_local.show_event_pins"
                                :label="$t('dialog_edit_widget_chart_component.display_automatic_event')"
                                hide-details
                                inset
                            />

                            <v-switch
                                v-model="chart_configuration_local.show_custom_pins"
                                :label="$t('dialog_edit_widget_chart_component.display_customize_event')"
                                hide-details
                                inset
                            />

                            <v-switch
                                v-model="chart_configuration_local.show_navigator"
                                :label="$t('dialog_edit_widget_chart_component.display_zoom_bar')"
                                hide-details
                                inset
                            />

                            <v-divider class="my-5" color="white" />

                            <v-text-field
                                v-model="chart_configuration_local.name_widget"
                                :label="$t('dialog_edit_widget_chart_component.widget_name')"
                                filled
                            />
                        </div>
                    </v-expand-transition>
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn text @click="closeDialogEditChart()">
                        <span class="font-color-light font-weight-bold">{{ $t('global.cancel') }}</span>
                    </v-btn>
                    <v-btn :disabled="!valid" color="secondary" text @click="validForm()">
                        <span :class="valid ? 'font-weight-bold secondary--text' : 'font-weight-bold font-color-light '">
                            {{ $t('global.validate') }}
                        </span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'DialogEditWidgetChartComponent',
    props: {
        edit: {
            type: Boolean,
            require: true,
        },
        chart_configuration: {
            type: Object,
            require: false,
        },
    },
    data() {
        return {
            chart_configuration_local: [],
            dialog_edit_chart: false,

            metrics: [
                {
                    id: 1,
                    libelle: this.$t('dialog_edit_widget_chart_component.volume'),
                },
                {
                    id: 2,
                    libelle: this.$t('dialog_edit_widget_chart_component.liquid_height'),
                },
                {
                    id: 3,
                    libelle: this.$t('dialog_edit_widget_chart_component.battery'),
                },
                {
                    id: 4,
                    libelle: this.$t('dialog_edit_widget_chart_component.emission'),
                },
            ],
            units: [
                {
                    id: 1,
                    libelle: this.$t('dialog_edit_widget_chart_component.liter'),
                },
                {
                    id: 2,
                    libelle: '%',
                },
            ],
        }
    },
    computed: {
        valid() {
            return (
                this.chart_configuration_local.metric !== null &&
                this.chart_configuration_local.unit !== null &&
                this.chart_configuration_local.show_alert !== null &&
                this.chart_configuration_local.show_navigator !== null &&
                this.chart_configuration_local.show_event_pins !== null &&
                this.chart_configuration_local.show_custom_pins !== null &&
                this.chart_configuration_local.name_widget !== null &&
                this.chart_configuration_local.name_widget !== ''
            )
        },
    },
    methods: {
        openDialogEditChart() {
            if (this.edit) {
                this.chart_configuration_local = JSON.parse(JSON.stringify(this.chart_configuration))
            }
            this.dialog_edit_chart = true
        },

        validForm() {
            if (this.edit) {
                this.$emit('editChartConfiguration', this.chart_configuration_local)
                this.closeDialogEditChart()
            }
        },

        closeDialogEditChart() {
            this.chart_configuration_local = {}
            this.dialog_edit_chart = false
        },
    },
}
</script>
