<template>
    <div id="dialog-manage-widget-component">
        <v-dialog v-model="dialog_manage_widgets" max-width="500px">
            <v-card data-cy="dialog-manage-widgets">
                <v-card-title>
                    <span class="font-weight-bold">
                        {{ $t('dialog_manage_widget_component.configure_widgets') }}
                    </span>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col v-for="widget in order_configuration_equipment" :key="widget.widget_name" class="col-sm-4" cols="6">
                            <div class="mx-auto d-block square-125">
                                <div
                                    :class="getClassToUse(widget, 'square-125 manage-widget-container')"
                                    :data-cy="widget.widget_name"
                                    @click="activateWidget(widget)"
                                >
                                    <v-icon :class="getClassToUse(widget, 'manage-widget-icon')">
                                        {{
                                            widget_display[widget.widget_name]
                                                ? widget_display[widget.widget_name].icon
                                                : 'mdi-information-outline'
                                        }}
                                    </v-icon>
                                    <br />
                                    <span :class="getClassToUse(widget, 'manage-widget-name')">
                                        {{
                                            widget_display[widget.widget_name]
                                                ? widget_display[widget.widget_name].display_name
                                                : widget.widget_name
                                        }}
                                    </span>
                                    <br />
                                    <span
                                        v-if="isAlreadyUse(widget)"
                                        class="font-italic font-12 font-color-light tab-opacity"
                                        data-cy="widget-is-already-use"
                                    >
                                        {{ $t('dialog_manage_widget_component.already_user') }}
                                    </span>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn data-cy="cancel-btn" text @click="closeDialogManageWidget()">
                        <span class="font-color-light font-weight-bold">{{ $t('global.cancel') }}</span>
                    </v-btn>

                    <v-btn
                        :disabled="!valid"
                        :loading="loading_edit"
                        class="btn-secondary-loader"
                        color="primary"
                        data-cy="submit-btn"
                        text
                        @click="updatePageOrderConfiguration()"
                    >
                        <span :class="(valid ? 'secondary--text' : 'font-color-light') + ' font-weight-bold'">
                            {{ $t('global.add') }}
                        </span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'DialogManageWidgetComponent',
    created() {
        this.setDefaultWidgetDisplay()
    },
    data() {
        return {
            dialog_manage_widgets: false,
            loading_edit: false,
            widget_display: {},
        }
    },
    computed: {
        valid() {
            let result = false
            Object.keys(this.widget_display).forEach((key) => {
                if (this.widget_display[key].active) {
                    result = true
                }
            })
            return result
        },
        order_configuration_equipment() {
            return this.$store.getters['page_order_configuration/order_configuration_equipment']
        },
    },
    methods: {
        setDefaultWidgetDisplay() {
            this.widget_display = {
                'information-asset-component': {
                    icon: 'mdi-information-outline',
                    display_name: this.$t('dialog_manage_widget_component.information_asset'),
                    active: false,
                },
                'chart-level-component': {
                    icon: 'mdi-chart-line',
                    display_name: this.$t('dialog_manage_widget_component.evolution_level_liter'),
                    active: false,
                },
                'info-level-component': {
                    icon: 'mdi-remote',
                    display_name: this.$t('dialog_manage_widget_component.information_device_level'),
                    active: false,
                },
                'estimated-days-exhaustion-component': {
                    icon: 'mdi-devices',
                    display_name: this.$t('dialog_manage_widget_component.estimated_days_exhaustion'),
                    active: false,
                },
                'information-device-component': {
                    icon: 'mdi-remote',
                    display_name: this.$t('dialog_manage_widget_component.information_device'),
                    active: false,
                },
                'fullness-history-component': {
                    icon: 'mdi-truck',
                    display_name: this.$t('dialog_manage_widget_component.fullness_history'),
                    active: false,
                },
                'consumption-report-component': {
                    icon: 'mdi-devices',
                    display_name: this.$t('dialog_manage_widget_component.consumption_report'),
                    active: false,
                },
                'image-equipment-component': {
                    icon: 'mdi-panorama-outline',
                    display_name: this.$t('dialog_manage_widget_component.image_asset'),
                    active: false,
                },
                'economy-co2-component': {
                    icon: 'mdi-panorama-outline',
                    display_name: this.$t('dialog_manage_widget_component.economy_co2'),
                    active: false,
                },
                'alerts-configured-component': {
                    icon: 'mdi-bell',
                    display_name: this.$t('dialog_manage_widget_component.alerts_configured'),
                    active: false,
                },
                'asset-card-widget-component': {
                    icon: 'mdi-storage-tank-outline',
                    display_name: this.$t('dialog_manage_widget_component.asset_card_widget'),
                    active: false,
                },
                'oleo-asset-card-widget-component': {
                    icon: 'mdi-storage-tank-outline',
                    display_name: this.$t('dialog_manage_widget_component.oleo_asset_card_widget'),
                    active: false,
                },
                'device-traited-data-by-device-referentiel-metric-datatable-widget-component': {
                    icon: 'mdi-database-outline',
                    display_name: this.$t('dialog_manage_widget_component.list_statements'),
                    active: false,
                },
                'asset-localisation-widget-component': {
                    icon: 'mdi-map-marker-outline',
                    display_name: this.$t('dialog_manage_widget_component.location'),
                    active: false,
                },
            }
        },

        isAlreadyUse(widget) {
            let result = false
            this.order_configuration_equipment.forEach((config) => {
                if (config.widget_name === widget.widget_name && config.is_display) {
                    result = true
                }
            })

            return result
        },

        getClassToUse(widget, defaultClass) {
            let classToUse = defaultClass

            if (this.isAlreadyUse(widget)) {
                classToUse += ' disable'
            } else if (this.widget_display[widget.widget_name] ? this.widget_display[widget.widget_name].active : false) {
                classToUse += ' active'
            }

            return classToUse
        },

        activateWidget(widget) {
            if (!this.isAlreadyUse(widget) && this.widget_display[widget.widget_name]) {
                this.widget_display[widget.widget_name].active = !this.widget_display[widget.widget_name].active
            }
        },

        updatePageOrderConfiguration() {
            this.loading_edit = true

            this.order_configuration_equipment.forEach((config) => {
                if (this.widget_display[config.widget_name] && !config.is_display) {
                    config.is_display = this.widget_display[config.widget_name].active
                }
            })

            this.updateOrCreatePageOrderConfiguration(
                this.order_configuration_equipment,
                this.$t('dialog_manage_widget_component.add_success')
            )
            //Fake async method
            document.addEventListener('finally', () => {
                this.loading_edit = false
                this.closeDialogManageWidget()
            })
        },

        closeDialogManageWidget() {
            this.dialog_manage_widgets = false
            this.setDefaultWidgetDisplay()
        },
    },
}
</script>

<style>
.square-125 {
    height: 125px;
    width: 125px;
}

.manage-widget-container {
    border: 1px var(--v-default_text_light-base) solid;
    border-radius: 3px;
    padding: 5px;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    cursor: pointer;
}

.manage-widget-container.disable {
    background: var(--v-background-base);
    border: none;
    cursor: default;
}

.manage-widget-container.active {
    border: 1px var(--v-primary-base) solid;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.manage-widget-icon {
    margin-bottom: 4px;
    color: var(--v-default_text-base);
}

.manage-widget-icon.disable {
    color: var(--v-default_text_light-base);
    opacity: 0.6;
}

.manage-widget-icon.active {
    color: var(--v-primary-base);
}

.manage-widget-name {
    font-size: 14px;
}

.manage-widget-name.disable {
    color: var(--v-default_text_light-base) !important;
    opacity: 0.6;
}

.manage-widget-name.active {
    color: var(--v-primary-base) !important;
}
</style>
