var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"map-position-history-widget-component"}},[_c('widget-wrapper-component',{attrs:{"title":_vm.$t('map_position_history_widget_component.list_positions')},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.device)?[_c('date-range-picker-component',{staticClass:"mb-4",attrs:{"date_range":_vm.date_range,"loading":_vm.fetching_coordinate},on:{"update":_vm.updateRangeDate}}),_c('div',{staticClass:"display-flex-align-center mb-2 font-12 font-color-normal"},[_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.$t('map_position_history_widget_component.start_journey'))+" ")]),_c('svg',{staticClass:"my-auto",attrs:{"height":"14","viewBox":"0 0 300 40","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"fill":"#1DB9F3","fill-opacity":"0.6","height":"32","width":"267.396","x":"17.592","y":"4"}}),_c('ellipse',{attrs:{"cx":"20","cy":"20","fill":"#EA4335","rx":"20","ry":"20"}}),_c('ellipse',{attrs:{"cx":"100","cy":"20","fill":"#1DB9F3","rx":"20","ry":"20"}}),_c('ellipse',{attrs:{"cx":"200","cy":"20","fill":"#1DB9F3","rx":"20","ry":"20"}}),_c('ellipse',{attrs:{"cx":"280","cy":"20","fill":"#65B265","rx":"20","ry":"20"}})]),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t('map_position_history_widget_component.end_journey'))+" ")])]),_c('gmap-map',{ref:"gmap",staticClass:"google-map-container",attrs:{"center":{ lat: 47, lng: 5 },"zoom":20},on:{"click":function($event){return _vm.mapFitBounds(_vm.$refs.gmap, _vm.markers)}}},[_vm._l((_vm.markers),function(marker,index){return [_c('gmap-marker',{key:index,attrs:{"icon":{
                                url: `/images/map_marker/${marker.image}.svg`,
                                scaledSize: {
                                    width: marker.image !== 'polyline_marker' ? 12 : 8,
                                    height: marker.image !== 'polyline_marker' ? 12 : 8,
                                },
                            },"position":{ lat: marker.latitude, lng: marker.longitude }},on:{"click":function($event){return _vm.openMarkerByIndex(index)}}},[_c('gmap-info-window',{attrs:{"opened":_vm.opened_marker_index === index},on:{"closeclick":function($event){_vm.opened_marker_index = null}}},[_c('div',{staticClass:"text-skin-default"},[_vm._v(_vm._s(marker.date_releve))])])],1)]}),_c('gmap-polygon',{attrs:{"options":{ geodesic: true, strokeColor: '#1DB9F3', strokeOpacity: 0.6, strokeWeight: 6 },"path":_vm.path}})],2)]:[_vm._v(" "+_vm._s(_vm.$t('map_position_history_widget_component.no_geolocation_sensor'))+" ")]]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }