<template>
    <div id="asset-additional-fields-widget-component">
        <widget-wrapper-component :title="$t('asset_additional_fields_widget_component.additional_equipment_information')">
            <template v-slot:default>
                <template
                    v-if="
                        asset_module.additional_fields_form_configuration &&
                        Object.keys(asset_module.additional_fields_form_configuration ?? {}).length > 0
                    "
                >
                    <template v-for="key in Object.keys(asset_module.additional_fields_form_configuration)">
                        <div class="mb-2 font-12" :key="key">
                            <span class="font-color-medium">
                                {{ asset_module.additional_fields_form_configuration[key].label }} </span
                            >:
                            <span class="font-weight-bold font-color-normal">
                                {{ asset.additional_fields[key] ?? '-' }}
                            </span>
                        </div>
                    </template>
                </template>

                <template v-else>
                    {{ $t('asset_additional_fields_widget_component.no_additional_information') }}
                </template>
            </template>
        </widget-wrapper-component>
    </div>
</template>

<script>
import WidgetWrapperComponent from '@/components/Equipment/Generic/Widget/WidgetWrapperComponent.vue'

export default {
    name: 'AssetAdditionalFieldsWidgetComponent',
    components: { WidgetWrapperComponent },
    props: {
        asset: {
            type: Object,
            required: true
        },
        asset_module: {
            type: Object,
            required: true
        }
    }
}
</script>