<template>
    <div id="estimated-days-exhaustion-component">
        <v-card class="elevation-0">
            <v-card-title>
                <header-widget-component
                    :is_open="is_open"
                    :title="
                        asset.watch_type === -1
                            ? $t('estimated_days_exhaustion_component.title')
                            : $t('estimated_days_exhaustion_component.title_filling')
                    "
                    widget_name="estimated-days-exhaustion-component"
                />
            </v-card-title>

            <v-expand-transition>
                <div v-show="is_open">
                    <v-card-text class="pt-2 pb-4">
                        <v-row class="text-center mb-1">
                            <v-col class="pa-0">
                                <span class="font-color-light font-12">
                                    {{ $t('estimated_days_exhaustion_component.days_before_exhaustion') }}
                                </span>
                                <p class="mb-0 font-weight-bold font-18">
                                    {{ days_before_exhaustion }}
                                </p>
                            </v-col>
                            <v-divider color="white" vertical />
                            <v-col class="pa-0">
                                <span class="font-color-light font-12">
                                    {{
                                        asset.watch_type === -1
                                            ? $t('estimated_days_exhaustion_component.exhaustion_estimated')
                                            : $t('estimated_days_exhaustion_component.filling_estimated')
                                    }}
                                </span>
                                <p class="mb-0 font-weight-bold font-18">{{ date_before_exhaustion }}</p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </div>
            </v-expand-transition>
        </v-card>
    </div>
</template>

<script>
import HeaderWidgetComponent from './HeaderWidgetComponent'

export default {
    name: 'EstimatedDaysExhaustionComponent',
    components: { HeaderWidgetComponent },
    props: {
        is_open: {
            type: Boolean,
            required: false,
            default: false,
        },
        asset: {
            type: Object,
            required: true,
            default: function () {
                return {
                    days_before_exhaustion: null,
                    watch_type: 1,
                }
            },
        },
    },
    computed: {
        days_before_exhaustion() {
            let daysBeforeExhaustion = this.asset.days_before_exhaustion + ' ' + this.$t('global.days')
            if (this.asset.days_before_exhaustion <= 0) {
                daysBeforeExhaustion = '-'
            } else if (this.asset.days_before_exhaustion <= 1) {
                daysBeforeExhaustion = this.asset.days_before_exhaustion + ' ' + this.$t('global.days')
            }

            return daysBeforeExhaustion
        },
        date_before_exhaustion() {
            let dateBeforeExhaustion = this.moment(new Date()).add(this.asset.days_before_exhaustion, 'days').format(this.date_format)
            if (this.asset.days_before_exhaustion <= 0) {
                dateBeforeExhaustion = '-'
            }
            return dateBeforeExhaustion
        },
    },
}
</script>