var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"alerts-configured-component"}},[_c('v-card',{staticClass:"elevation-0 overflow-visible"},[_c('v-card-title',[_c('header-widget-component',{attrs:{"actions":[
                    {
                        title: _vm.$t('list_tank_equipmement_component.manage_alert'),
                        icon: 'mdi-bell-outline',
                        emit: 'goToManageAlertPage',
                        has_access: _vm.userHasAccess('BASE', _vm.role_vision),
                    },
                ],"is_open":_vm.is_open,"title":_vm.$t('alerts_configured_component.alerts_parameter'),"widget_name":"alerts-configured-component"},on:{"goToManageAlertPage":function($event){return _vm.$router.push('/equipment/manage_alert/' + _vm.asset.id_asset)}}})],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.is_open),expression:"is_open"}]},[_c('v-card-text',{staticClass:"pt-0 pb-4"},[(!_vm.loading_alerts)?[(_vm.alerts.length > 0)?[_vm._l((_vm.alerts.filter((x) => x.triggered)),function(alert){return _c('v-row',{key:alert.id_alert,staticClass:"ma-0 mb-2"},[_c('badge-alert-component',{attrs:{"alert":alert},on:{"refresh:alert":_vm.prepareFetchAlertsByAsset}}),_c('div',{staticClass:"ml-2"},[_c('p',{staticClass:"font-color-medium font-weight-bold font-12 mb-n2 mt-1"},[_vm._v(" "+_vm._s(_vm.$t('alerts_configured_component.alert'))+" ")]),_c('p',{staticClass:"font-14 mb-0"},[_vm._v(_vm._s(alert.label))])])],1)}),(_vm.alerts.filter((x) => x.triggered).length > 0 && _vm.alerts.filter((x) => !x.triggered).length > 0)?_c('v-divider',{staticClass:"my-2"}):_vm._e(),_vm._l((_vm.alerts.filter((x) => !x.triggered)),function(alert){return _c('v-row',{key:alert.id_alert,staticClass:"ma-0 mb-2"},[_c('badge-alert-component',{attrs:{"alert":alert},on:{"refresh:alert":_vm.prepareFetchAlertsByAsset}}),_c('div',{staticClass:"ml-2"},[_c('p',{staticClass:"font-color-medium font-weight-bold font-12 mb-n2 mt-1"},[_vm._v(" "+_vm._s(_vm.$t('alerts_configured_component.alert'))+" ")]),_c('p',{staticClass:"font-14 mb-0"},[_vm._v(_vm._s(alert.label))])])],1)})]:[_c('p',{staticClass:"mb-1 text-center"},[_vm._v(" "+_vm._s(_vm.$t('alerts_configured_component.no_alert_set'))+" ")])]]:_vm._l((2),function(n){return _c('v-skeleton-loader',{key:n,attrs:{"loading":"","type":"list-item-avatar"}})})],2)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }