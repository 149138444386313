var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"asset-localisation-widget-component"}},[_c('widget-wrapper-component',{attrs:{"title":_vm.$t('asset_localisation_widget_component.location')},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.asset.domicile)?[_c('div',{staticClass:"mb-4"},[_c('p',{staticClass:"text-uppercase font-color-medium font-14 font-weight-bold mb-2"},[_vm._v(" "+_vm._s(_vm.$t('asset_localisation_widget_component.address'))+" ")]),_c('div',{staticClass:"d-flex gap-1 mb-2 font-12"},[_c('v-icon',[_vm._v("mdi-map-marker-outline")]),_vm._v(" "+_vm._s(_vm.displayToHumanDomicileAddress(_vm.asset.domicile))+" ")],1),_c('div',{staticClass:"d-flex gap-1 mb-2"},[_c('v-icon',[_vm._v("mdi-home")]),_vm._v(" "+_vm._s(_vm.asset.domicile.address_name)+" ")],1)]),(
                        _vm.asset.domicile.contact_first_name ||
                        _vm.asset.domicile.contact_last_name ||
                        _vm.asset.domicile.contact_mail ||
                        _vm.asset.domicile.contact_phone ||
                        _vm.asset.domicile.contact_note
                    )?_c('div',[_c('p',{staticClass:"text-uppercase font-color-medium font-weight-bold mb-2"},[_vm._v(" "+_vm._s(_vm.$t('asset_localisation_widget_component.on_site_contact'))+" ")]),_c('div',{staticClass:"text-12 d-flex flex-column gap-2 mb-2"},[(_vm.asset.domicile.contact_first_name || _vm.asset.domicile.contact_last_name)?_c('div',{staticClass:"flex gap-1"},[_c('v-icon',{staticClass:"text-skin-medium"},[_vm._v("mdi-account")]),_c('span',{staticClass:"text-skin-default"},[_vm._v(" "+_vm._s(_vm.asset.domicile.contact_first_name)+" "+_vm._s(_vm.asset.domicile.contact_last_name)+" ")])],1):_vm._e(),(_vm.asset.domicile.contact_mail)?_c('div',{staticClass:"d-flex gap-1 mb-2"},[_c('v-icon',{staticClass:"text-skin-medium"},[_vm._v("mdi-at")]),_c('span',{staticClass:"text-skin-default"},[_vm._v(_vm._s(_vm.asset.domicile.contact_mail))])],1):_vm._e(),(_vm.asset.domicile.contact_phone)?_c('div',{staticClass:"d-flex gap-1 mb-2"},[_c('v-icon',{staticClass:"text-skin-medium"},[_vm._v("mdi-phone")]),_c('span',{staticClass:"text-skin-default"},[_vm._v(_vm._s(_vm.asset.domicile.contact_phone))])],1):_vm._e(),(_vm.asset.domicile.contact_note)?_c('div',{staticClass:"d-flex gap-1 mb-2"},[_c('v-icon',{staticClass:"text-skin-medium"},[_vm._v("mdi-pencil")]),_c('span',{staticClass:"text-skin-default"},[_vm._v(_vm._s(_vm.asset.domicile.contact_note))])],1):_vm._e()])]):_vm._e(),_c('gmap-map',{staticClass:"google-map-container",attrs:{"center":{ lat: parseFloat(_vm.asset.domicile.latitude), lng: parseFloat(_vm.asset.domicile.longitude) },"zoom":16}},[_c('google-map-cluster',[_c('gmap-marker',{attrs:{"draggable":false,"icon":{
                                url: _vm.asset_module.map_marker,
                                scaledSize: { width: 45, height: 45 },
                            },"position":{ lat: parseFloat(_vm.asset.domicile.latitude), lng: parseFloat(_vm.asset.domicile.longitude) }}})],1)],1)]:[_vm._v(" "+_vm._s(_vm.$t('asset_localisation_widget_component.no_domicile_associated'))+" ")]]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }