<template>
    <div id="boostherm-widget-component">
        <widget-wrapper-component :title="$t('boostherm_widget_component.survey_summary')">
            <template v-slot:default>
                <v-fade-transition hide-on-leave>
                    <template v-if="!buildingConfig">
                        <div>
                            <template v-if="asset.last_datas">
                                <template v-for="(config, index) in configs">
                                    <v-card :key="index" class="mb-4 pa-4" color="#F5F7FB" elevation="0">
                                        <div class="w-100">
                                            <div class="display-flex-align-center gap-1">
                                                <v-icon class="font-color-normal" size="small"> {{ config.icon }} </v-icon>
                                                <span class="font-16 font-weight-bold"> {{ config.title }} </span>
                                            </div>

                                            <hr class="mb-1" />

                                            <div class="display-flex-align-center justify-space-around gap-2 px-2 font-14">
                                                <div v-for="(datas, index) in config.datas" :key="index">
                                                    <p class="font-12 font-color-medium mb-0">{{ datas.title }}</p>
                                                    <p v-for="(value, index) in datas.datas" :key="index" class="font-weight-bold">
                                                        {{ displayToHumanNumberWithSpace(value.value) }} {{ value.unit }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </v-card>
                                </template>
                            </template>

                            <template v-else>
                                {{ $t('boostherm_widget_component.no_data_available_at_the_moment') }}
                            </template>
                        </div>
                    </template>

                    <template v-else>
                        <v-skeleton-loader type="image" />
                    </template>
                </v-fade-transition>
            </template>
        </widget-wrapper-component>
    </div>
</template>

<script>
import displayHuman from '@/mixin/displayHuman'
import WidgetWrapperComponent from '@/components/Equipment/Generic/Widget/WidgetWrapperComponent.vue'

export default {
    name: 'BoosthermWidgetComponent',
    components: { WidgetWrapperComponent },
    mixins: [displayHuman],
    props: {
        asset: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            configs: [],
            buildingConfig: true,
        }
    },
    methods: {
        buildWidgetConfiguration() {
            this.buildingConfig = true

            if (this.asset.last_datas['economy']) {
                this.configs.push({
                    icon: 'mdi-currency-eur',
                    title: this.$t('boostherm_widget_component.economy'),
                    datas: [
                        {
                            title: this.$t('boostherm_widget_component.today'),
                            datas: [{ value: this.displayAssetLastData('economy', 1), unit: '€' }],
                        },
                        {
                            title: this.$t('boostherm_widget_component.average_per_day_activity'),
                            datas: [{ value: this.displayAssetLastData('economy', 2), unit: '€' }],
                        },
                        {
                            title: this.$t('boostherm_widget_component.since_start_up'),
                            datas: [{ value: this.displayAssetLastData('economy', 0), unit: '€' }],
                        },
                    ],
                })
            }

            if (this.asset?.last_datas['water']) {
                this.configs.push({
                    icon: 'mdi-water',
                    title: this.$t('boostherm_widget_component.domestic_hot_water_consumption'),
                    datas: [
                        {
                            title: this.$t('boostherm_widget_component.today'),
                            datas: [{ value: this.displayAssetLastData('water', 1), unit: 'L' }],
                        },
                        {
                            title: this.$t('boostherm_widget_component.average_per_day_activity'),
                            datas: [{ value: this.displayAssetLastData('water', 2), unit: 'm3' }],
                        },
                        {
                            title: this.$t('boostherm_widget_component.since_start_up'),
                            datas: [{ value: this.displayAssetLastData('water', 0), unit: 'm3' }],
                        },
                    ],
                })
            }

            if (this.asset?.last_datas['energy']) {
                this.configs.push({
                    icon: 'mdi-lightning-bolt',
                    title: this.$t('boostherm_widget_component.energy_saved'),
                    datas: [
                        {
                            title: this.$t('boostherm_widget_component.today'),
                            datas: [{ value: this.displayAssetLastData('energy', 1), unit: 'kWh' }],
                        },
                        {
                            title: this.$t('boostherm_widget_component.average_per_day_activity'),
                            datas: [{ value: this.displayAssetLastData('energy', 2), unit: 'kWh' }],
                        },
                        {
                            title: this.$t('boostherm_widget_component.since_start_up'),
                            datas: [{ value: this.displayAssetLastData('energy', 0), unit: 'kWh' }],
                        },
                    ],
                })
            }

            if (this.asset?.last_datas['gaz']) {
                this.configs.push({
                    icon: 'mdi-gas-cylinder',
                    title: this.$t('boostherm_widget_component.gaz_consumed'),
                    datas: [
                        {
                            title: this.$t('boostherm_widget_component.today'),
                            datas: [
                                {
                                    value: this.displayAssetLastData('gaz', 1),
                                    unit: 'm3',
                                },
                            ],
                        },
                        {
                            title: this.$t('boostherm_widget_component.average_per_day_activity'),
                            datas: [{ value: this.displayAssetLastData('gaz', 2), unit: 'm3' }],
                        },
                        {
                            title: this.$t('boostherm_widget_component.since_start_up'),
                            datas: [
                                {
                                    value: this.displayAssetLastData('gaz', 0),
                                    unit: 'm3',
                                },
                            ],
                        },
                    ],
                })
            }

            this.buildingConfig = false
        },

        displayAssetLastData(key, index) {
            return this.asset?.last_datas[key] ? this.asset?.last_datas[key][index] : '-'
        },
    },
    created() {
        this.buildWidgetConfiguration()
    },
}
</script>
