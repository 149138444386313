<template>
    <div id="oleo-asset-card-widget-component">
        <v-card class="elevation-0">
            <v-card-title>
                <header-widget-component
                    :actions="[
                        {
                            title: $t('list_tank_equipmement_component.edit_cuve'),
                            icon: 'mdi-pencil-outline',
                            emit: 'openManageNameEquipmentDialogComponent',
                            has_access: userHasAccess('TNK', role_all_access),
                        },
                        {
                            title: $t('list_tank_equipmement_component.manage_alert'),
                            icon: 'mdi-bell-outline',
                            emit: 'goToManageAlertPage',
                            has_access: userHasAccess('BASE', role_vision),
                        },
                    ]"
                    :is_open="is_open"
                    :title="asset.name"
                    widget_name="oleo-asset-card-widget-component"
                    v-on:goToManageAlertPage="$router.push('/equipment/manage_alert/' + asset.id_asset)"
                    v-on:openManageEquipmentDialogComponent="$refs.manageEquipmentComponent.openManageEquipmentDialogComponent(asset)"
                    v-on:openManageNameEquipmentDialogComponent="$refs.manageNameEquipmentDialogComponent.openManageNameEquipmentDialogComponent(asset)"
                />
            </v-card-title>

            <v-expand-transition>
                <div v-show="is_open">
                    <v-card-text>
                        <div class="d-flex justify-center">
                            <display-asset-level-component :asset="asset" :scale="is_mobile ? 0.8 : 1.2" show_indicator />
                        </div>

                        <v-row class="font-12 mx-0 my-4">
                            <v-col class="px-4 py-1 mb-1" cols="6" md="3">
                                <span class="font-color-medium">{{ $t('list_tank_equipmement_component.volume') }}</span>
                                <p class="mb-0 font-weight-bold">
                                    {{
                                        asset.is_subscription_device_active
                                            ? numberWithSpaces(Math.round(asset.actual_volume), 0) + ' L'
                                            : '-'
                                    }}
                                </p>
                            </v-col>
                            <v-divider color="white" vertical />

                            <v-col class="px-4 py-1 mb-1" cols="6" md="3">
                                <span class="font-color-medium">{{ $t('list_tank_equipmement_component.useful_hollow') }}</span>
                                <p class="mb-0 font-weight-bold">
                                    {{
                                        asset.is_subscription_device_active
                                            ? numberWithSpaces(Math.round(asset.useful_hollow), 0) + ' L'
                                            : '-'
                                    }}
                                </p>
                            </v-col>
                            <v-divider color="white" vertical v-if="!is_mobile" />

                            <v-col class="px-4 py-1 mb-1" cols="6" md="3">
                                <span class="font-color-medium">{{ $t('list_tank_equipmement_component.level') }}</span>
                                <p class="mb-0 font-weight-bold">
                                    {{
                                        asset.is_subscription_device_active
                                            ? numberWithSpaces(Math.round(asset.remaining_level), 0) + ' %'
                                            : '-'
                                    }}
                                </p>
                            </v-col>
                            <v-divider color="white" vertical />

                            <v-col class="px-4 py-1 mb-1" cols="6" md="3">
                                <span class="font-color-medium">{{ $t('list_tank_equipmement_component.product') }}</span>
                                <p class="mb-0 font-weight-bold">
                                    {{ asset.asset_liquid ? asset.asset_liquid.name : '-' }}
                                </p>
                            </v-col>
                        </v-row>

                        <template v-for="(device, index) in asset.devices">
                            <div :key="index" class="d-flex align-center gap-2 mb-2">
                                <img :src="device.path_image_device_referentiel" height="40" />
                                <div class="d-flex align-center flex-wrap gap-2">
                                    {{ device.device_number }}
                                    <chips-last-statement :date="device.date_last_emission" />
                                    <display-device-battery-level-component :battery="device.battery" />
                                </div>
                            </div>
                        </template>

                        <template v-if="!!tags.find((tag) => tag.label === 'Commande automatique')">
                            <v-alert color="success" icon="mdi-refresh" text dense>
                                {{ $t('oleo_asset_card_widget_component.automatic_order') }}
                            </v-alert>
                        </template>
                    </v-card-text>
                </div>
            </v-expand-transition>
        </v-card>

        <manage-equipment-dialog-component
            v-if="userHasAccess('TNK', this.role_admin)"
            ref="manageEquipmentComponent"
            data-cy="manage-equipment-component"
            v-on:refresh:tank="$emit('refresh:tank')"
        />

        <manage-name-equipment-dialog-component
            v-if="userHasAccess('TNK', this.role_edit)"
            ref="manageNameEquipmentDialogComponent"
            data-cy="manage-name-equipment-dialog-component"
            v-on:refresh:tank="$emit('refresh:tank')"
        />
    </div>
</template>

<script>
import DisplayAssetLevelComponent from '@/components/Equipment/LiquidLevelComponent/DisplayAssetLevelComponent.vue'
import ChipsLastStatement from '@/components/Global/ChipsLastStatement.vue'
import HeaderWidgetComponent from '@/components/Widget/HeaderWidgetComponent.vue'
import ManageEquipmentDialogComponent from '@/components/EquipmentManagement/ManageEquipmentDialogComponent.vue'
import AssetRepository from '@/repositories/AssetRepository'
import DisplayDeviceBatteryLevelComponent from '@/components/Global/DisplayDeviceBatteryLevelComponent.vue'
import ManageNameEquipmentDialogComponent from "../../EquipmentManagement/ManageNameEquipmentDialogComponent";

export default {
    name: 'OleoAssetCardWidgetComponent',
    components: {
        DisplayDeviceBatteryLevelComponent,
        ManageEquipmentDialogComponent,
        HeaderWidgetComponent,
        DisplayAssetLevelComponent,
        ChipsLastStatement,
        ManageNameEquipmentDialogComponent
    },
    created() {
        this.getAssetTags()
    },
    methods: {
        getAssetTags() {
            AssetRepository.getAssetTags(this.asset.id_asset)
                .then((success) => {
                    this.tags = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
        },
    },
    data() {
        return {
            tags: [],
        }
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        is_open: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
}
</script>