<template>
    <div id="manage-equipment-component">
        <v-dialog v-model="dialog_manage_equipment" max-width="800px">
            <v-card>
                <v-card-title>
                    <p class="text-uppercase font-weight-bold">{{ $t('manage_equipment_component.title') }}</p>
                </v-card-title>

                <v-card-text class="pb-0">
                    <span class="font-weight-bold">{{ $t('manage_equipment_component.tank_address') }}</span>
                    <v-radio-group v-model="type_address" class="mt-1" @change="hideMap()">
                        <v-radio value="existing_address">
                            <template v-slot:label>
                                <span>{{ $t('manage_equipment_component.use_existing_address') }}</span>
                            </template>
                        </v-radio>

                        <v-radio value="other_address" @click="hideMap">
                            <template v-slot:label>
                                <span>{{ $t('manage_equipment_component.use_other_address') }}</span>
                            </template>
                        </v-radio>

                        <v-radio value="geolocation" @click="geolocation()">
                            <template v-slot:label>
                                <span>{{ $t('manage_equipment_component.geolocate_me') }}</span>
                            </template>
                        </v-radio>
                    </v-radio-group>

                    <div v-if="type_address === 'other_address'">
                        <p class="font-14 mb-0">{{ $t('manage_equipment_component.dont_find_your_address') }}</p>
                        <p class="font-14 text-decoration-underline">
                            <a @click="showMap">{{ $t('manage_equipment_component.placing_point_on_map') }}</a>
                        </p>
                    </div>

                    <div id="existing_address">
                        <GmapAutocomplete
                            v-if="type_address === 'other_address' && !show_map"
                            class="w-100"
                            @place_changed="setGoogleAddress"
                        />

                        <v-select
                            v-else
                            v-model="selected_address"
                            :item-text="item_text_address_textfield"
                            :items="items_address_textfield"
                            :label="label_address_textfield"
                            :loading="loading_address"
                            :menu-props="{ bottom: true, offsetY: true }"
                            class="mb-2"
                            filled
                            hide-details
                            return-object
                        >
                            <template v-slot:no-data>
                                <v-list class="text-center">
                                    {{ $t('manage_equipment_component.no_address_found') }}
                                </v-list>
                            </template>

                            <template v-slot:item="{ item }">
                                <span v-if="!show_map">
                                    {{ item.address + ' ' + item.postal_code + ' ' + item.city + ' ' + item.country }}
                                </span>

                                <span v-else>
                                    {{ item.formatted_address }}
                                </span>
                            </template>
                        </v-select>

                        <v-text-field :label="$t('manage_equipment_component.domicile_name')" filled />
                    </div>

                    <gmap-map v-if="show_map" ref="gmap" :center="center" :zoom="zoom" class="google-map-container mb-4" @click="putMarker">
                        <google-map-cluster>
                            <gmap-marker
                                v-if="marker.latitude !== ''"
                                :clickable="true"
                                :draggable="false"
                                :icon="{ url: '/images/icons/marker.png' }"
                                :position="{ lat: marker.latitude, lng: marker.longitude }"
                            />
                        </google-map-cluster>
                    </gmap-map>

                    <div id="equipment_model">
                        <span class="font-weight-bold mb-2">{{ $t('manage_equipment_component.tank_model') }}</span>
                        <v-switch v-model="know_model" class="mt-1" inset>
                            <template v-slot:label>
                                <span class="font-14">{{ $t('manage_equipment_component.i_know_the_model') }}</span>
                            </template>
                        </v-switch>

                        <div v-if="know_model" class="know_model">
                            <v-select
                                v-model="local_asset.brand_reference"
                                :disabled="brand_reference.length === 0"
                                :items="brand_reference"
                                :label="$t('manage_equipment_component.tank_mark')"
                                :loading="loading_brand"
                                :menu-props="{ bottom: true, offsetY: true }"
                                :rules="[rules.required]"
                                filled
                                item-text="name"
                                item-value="id_brand_reference"
                                return-object
                                @change="getModelReference()"
                            />
                            <v-select
                                v-model="local_asset.model_reference"
                                :disabled="model_reference.length === 0"
                                :items="model_reference"
                                :label="$t('manage_equipment_component.model_name')"
                                :loading="loading_model"
                                :menu-props="{ bottom: true, offsetY: true }"
                                :rules="[rules.required]"
                                filled
                                item-text="trade_name"
                                item-value="id_model_reference"
                                return-object
                            />
                        </div>

                        <div v-else id="unknow-model">
                            <v-radio-group v-model="local_asset.type" class="mt-0">
                                <v-row class="justify-center text-center">
                                    <v-col class="radio-tank" cols="3">
                                        <v-radio :value="3" class="justify-center mb-0 pl-2" />
                                        <v-img
                                            class="ml-auto mr-auto my-2 pointer"
                                            max-height="150px"
                                            max-width="150px"
                                            src="/images/asset/installation_model/Cuve-cylindrique-debout.png"
                                            @click="local_asset.type = 3"
                                        />
                                        <p class="font-10 line-height-12" @click="local_asset.type = 3">
                                            {{ $t('manage_equipment_component.vertical_cylindrical') }}
                                        </p>
                                    </v-col>

                                    <v-col class="radio-tank" cols="3">
                                        <v-radio :value="1" class="justify-center mb-0 pl-2" />
                                        <v-img
                                            class="ml-auto mr-auto my-2 pointer"
                                            max-height="150px"
                                            max-width="150px"
                                            src="/images/asset/installation_model/cuve-cubique.png"
                                            @click="local_asset.type = 1"
                                        />
                                        <p class="font-10 line-height-12" @click="local_asset.type = 1">
                                            {{ $t('manage_equipment_component.rectangular') }}
                                        </p>
                                    </v-col>

                                    <v-col class="text-center radio-tank" cols="3">
                                        <v-radio :value="5" class="justify-center mb-0 pl-2" />
                                        <v-img
                                            class="ml-auto mr-auto my-2 pointer"
                                            max-height="150px"
                                            max-width="150px"
                                            src="/images/asset/installation_model/cuve-IBC.png"
                                            @click="local_asset.type = 5"
                                        />
                                        <p class="font-10 line-height-12" @click="local_asset.type = 5">IBC</p>
                                    </v-col>

                                    <v-col class="text-center radio-tank" cols="3">
                                        <v-radio :value="2" class="justify-center mb-0 pl-2" />
                                        <v-img
                                            class="ml-auto mr-auto my-2 pointer"
                                            max-height="150px"
                                            max-width="150px"
                                            src="/images/asset/installation_model/cuve_cylindrique1_checkbox.png"
                                            @click="local_asset.type = 2"
                                        />
                                        <p class="font-10 line-height-12" @click="local_asset.type = 2">
                                            {{ $t('manage_equipment_component.cylindrical_lying') }}
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-radio-group>

                            <div class="info-tank">
                                <v-text-field
                                    v-model="local_asset.volume_total"
                                    :label="$t('manage_equipment_component.volume_in_l')"
                                    :rules="[rules.required, rules.positive]"
                                    filled
                                    type="number"
                                />
                                <v-text-field
                                    v-model="local_asset.height"
                                    :label="$t('manage_equipment_component.height_in_cm')"
                                    :rules="[rules.required, rules.positive]"
                                    filled
                                    type="number"
                                />
                                <v-text-field
                                    v-model="local_asset.length"
                                    :label="$t('manage_equipment_component.inside_length')"
                                    :rules="[rules.required, rules.positive]"
                                    filled
                                    type="number"
                                />
                                <v-text-field
                                    v-model="local_asset.width"
                                    :label="$t('manage_equipment_component.inside_width')"
                                    :rules="[rules.required, rules.positive]"
                                    filled
                                    type="number"
                                />
                                <v-text-field
                                    v-model="model_offset"
                                    :label="$t('manage_equipment_component.offset')"
                                    :rules="[rules.required, rules.positive]"
                                    filled
                                    type="number"
                                />

                                <v-text-field
                                    v-model="model_offset_bottom"
                                    :label="$t('manage_equipment_component.offset_bottom')"
                                    :rules="[rules.required, rules.positive]"
                                    filled
                                    type="number"
                                />

                                <v-select
                                    v-model="index_watch_type"
                                    :items="watch_types"
                                    :label="$t('manage_equipment_component.monitoring_type')"
                                    :rules="[rules.required]"
                                    class="rounded-0"
                                    filled
                                    item-text="value"
                                    item-value="index"
                                />
                            </div>
                        </div>
                    </div>

                    <div id="equipment_details">
                        <span class="font-weight-bold mb-2">{{ $t('manage_equipment_component.tank_informations') }}</span>
                        <v-text-field
                            v-model="local_asset.name"
                            :label="$t('manage_equipment_component.tank_name')"
                            :rules="[rules.required]"
                            class="mt-2"
                            filled
                        />
                        <v-select
                            v-model="local_asset.asset_liquid"
                            :disabled="asset_liquids.length === 0"
                            :items="asset_liquids"
                            :label="$t('manage_equipment_component.content')"
                            :loading="loading_asset_liquids"
                            :menu-props="{ bottom: true, offsetY: true }"
                            :rules="[rules.required]"
                            filled
                            item-text="name"
                            item-value="id_asset_liquid"
                            return-object
                        />
                    </div>
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn text @click="closeDialogEditEquipment">
                        <span class="font-color-light font-weight-bold">{{ $t('global.cancel') }}</span>
                    </v-btn>
                    <v-btn
                        :disabled="invalid"
                        :loading="loading_edit"
                        class="btn-secondary-loader"
                        color="primary"
                        text
                        @click="editAsset()"
                    >
                        <span :class="!invalid ? 'secondary--text font-weight-bold' : 'font-color-light font-weight-bold'">
                            {{ $t('global.validate') }}
                        </span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import GoogleMapCluster from 'vue2-google-maps/dist/components/cluster'
import AddressRepository from '../../repositories/AddressRepository'
import BrandReferenceRepository from '@/repositories/BrandReferenceRepository'
import ModelRepository from '@/repositories/ModelRepository'
import ProfessionalRepository from '../../repositories/ProfessionalRepository'
import AssetRepository from '../../repositories/AssetRepository'

export default {
    name: 'ManageEquipmentComponent',
    components: {
        GoogleMapCluster,
    },
    created() {
        this.getBrandReference()
        this.getAssetLiquids()
    },
    data() {
        return {
            asset: {},
            asset_data: [],
            local_asset: [],

            dialog_manage_equipment: false,

            asset_liquids: [],
            loading_asset_liquids: false,

            know_model: false,
            model_reference: [],
            loading_model: false,

            brand_reference: [],
            loading_brand: false,

            model_offset: 0,
            model_offset_bottom: 0,
            index_watch_type: null,
            watch_types: [
                {
                    index: -1,
                    value: this.$t('manage_equipment_component.monitoring_consumption'),
                },
                {
                    index: 1,
                    value: this.$t('manage_equipment_component.monitoring_filling'),
                },
            ],

            show_map: false,
            zoom: 4,
            center: {
                lat: 46.804835694647416,
                lng: 2.6708984374999822,
            },
            marker: {
                latitude: '',
                longitude: '',
            },

            type_address: 'existing_address',
            loading_address: false,

            selected_address: '',
            existing_address: [],
            google_address: [],

            rules: {
                required: (value) => !!value || this.$t('manage_equipment_component.required_field'),
                positive: (value) => parseInt(value) > 0 || this.$t('manage_equipment_component.must_be_greater_than_zero'),
            },
            loading_edit: false,
        }
    },
    computed: {
        item_text_address_textfield() {
            return this.type_address === 'existing_address' || (this.type_address === 'other_address' && !this.show_map)
                ? 'address'
                : 'formatted_address'
        },
        items_address_textfield() {
            return this.type_address === 'existing_address' || (this.type_address === 'other_address' && !this.show_map)
                ? this.$store.getters['domiciles/domiciles']
                : this.google_address
        },
        label_address_textfield() {
            return this.type_address === 'existing_address' || (this.type_address === 'other_address' && !this.show_map)
                ? this.$t('manage_equipment_component.use_existing_address')
                : this.$t('manage_equipment_component.nearby_addresses')
        },
        invalid: function () {
            return !(
                this.local_asset.asset_liquid !== null &&
                this.local_asset.name !== '' &&
                ((this.know_model && this.local_asset.brand_reference !== null && this.local_asset.model_reference !== null) ||
                    (!this.know_model &&
                        this.model_offset !== '' &&
                        this.model_offset >= 0 &&
                        this.model_offset_bottom !== '' &&
                        this.model_offset_bottom >= 0 &&
                        this.local_asset.type !== '' &&
                        this.local_asset.type !== 0 &&
                        this.local_asset.length !== '' &&
                        this.local_asset.width !== '' &&
                        this.local_asset.height !== '' &&
                        this.local_asset.volume_total !== '')) &&
                this.selected_address !== '' &&
                this.index_watch_type !== null
            )
        },
    },
    watch: {
        asset: {
            handler() {
                this.resetForm()
                this.model_reference = []
                this.local_asset = JSON.parse(JSON.stringify(this.asset))
                this.index_watch_type = this.asset.watch_type
                this.selected_address = this.asset.domicile
                this.model_offset = this.asset.offset
                this.model_offset_bottom = this.asset.offset_bottom
                if (this.local_asset.brand_reference !== null) {
                    this.getModelReference()
                    this.know_model = true
                }
            },
        },

        type_address: {
            handler() {
                this.selected_address = ''
                if (this.type_address === 'existing_address' || (this.type_address === 'other_address' && !this.show_map)) {
                    this.selected_address = this.asset.domicile
                }
            },
            immediate: true,
        },
    },
    methods: {
        openManageEquipmentDialogComponent(asset) {
            this.asset = asset
            this.dialog_manage_equipment = true
        },
        getBrandReference() {
            this.loading_brand = true
            BrandReferenceRepository.geBrandReference('CUVE')
                .then((success) => {
                    this.brand_reference = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_brand = false
                })
        },

        getModelReference() {
            this.model_reference = []
            this.loading_model = true
            ModelRepository.getModelReference(this.local_asset.brand_reference.id_brand_reference)
                .then((success) => {
                    this.model_reference = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_model = false
                })
        },

        getAssetLiquids() {
            this.loading_asset_liquids = true
            ProfessionalRepository.getAssetLiquidsByProfessionalAndType(
                this.$store.getters['user_connected/user_connected'].id_professional,
                'cuve'
            )
                .then((success) => {
                    this.asset_liquids = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_asset_liquids = false
                })
        },

        setGoogleAddress(address) {
            let count = 0
            address.address_components.forEach((component) => {
                if (component.types.includes('street_number')) {
                    count++
                } else if (component.types.includes('route')) {
                    count++
                } else if (component.types.includes('locality')) {
                    count++
                } else if (component.types.includes('country')) {
                    count++
                } else if (component.types.includes('postal_code')) {
                    count++
                }
            })
            if (count >= 5) {
                this.selected_address = address
            } else {
                this.$store.commit('snackbar/showSnackbar', {
                    color: 'error',
                    text: this.$t('manage_equipment_component.selected_address_not_valid'),
                })
            }
        },

        putMarker(event) {
            this.marker.latitude = event.latLng.lat()
            this.marker.longitude = event.latLng.lng()
            this.getValidGoogleAddress()
        },

        getValidGoogleAddress() {
            this.google_address = []
            this.loading_address = true
            AddressRepository.getAddressFromGoogleApi(this.marker.latitude, this.marker.longitude)
                .then((success) => {
                    success.data.results.forEach((address) => {
                        let types = []
                        address.address_components.forEach((component) => {
                            component.types.forEach((type) => {
                                types.push(type)
                            })
                        })
                        if (
                            types.includes('street_number') &&
                            types.includes('route') &&
                            types.includes('locality') &&
                            types.includes('postal_code') &&
                            types.includes('country')
                        ) {
                            this.google_address.push(address)
                        }
                    })
                })
                .catch(() => {
                    this.$store.commit('snackbar/showSnackbar', {
                        color: 'error',
                        text: this.$t('manage_equipment_component.unable_retrieve_addresses'),
                    })
                })
                .finally(() => {
                    this.loading_address = false
                })
        },

        geolocation() {
            this.zoom = 18
            this.show_map = true
            navigator.geolocation.getCurrentPosition((pos) => {
                this.marker.latitude = pos.coords.latitude
                this.marker.longitude = pos.coords.longitude
                this.center.lat = pos.coords.latitude
                this.center.lng = pos.coords.longitude
                this.getValidGoogleAddress()
            })
        },

        showMap() {
            this.show_map = true
            this.zoom = 5
            this.center = {
                lat: 46.804835694647416,
                lng: 2.6708984374999822,
            }
        },

        hideMap() {
            this.selected_address = ''
            this.show_map = false
            this.google_address = []
            this.marker = {
                latitude: '',
                longitude: '',
            }
        },

        closeDialogEditEquipment() {
            this.dialog_manage_equipment = false
            this.resetForm()
        },

        resetForm() {
            this.model_offset = 0
            this.model_offset_bottom = 0
            this.index_watch_type = null
            this.type_address = 'existing_address'
            this.selected_address = this.local_asset.domicile
            this.zoom = 4
            this.center = {
                lat: 46.804835694647416,
                lng: 2.6708984374999822,
            }
            this.marker = {
                latitude: '',
                longitude: '',
            }
            this.show_map = false
        },

        editAsset() {
            this.loading_edit = true

            let asset = {}
            if (this.know_model) {
                asset = {
                    know_model_brand_asset: true,
                    id_brand_reference: this.local_asset.brand_reference.id_brand_reference,
                    id_model_reference: this.local_asset.model_reference.id_model_reference,
                    id_liquid: this.local_asset.asset_liquid.id_asset_liquid,
                    offset: this.model_offset,
                    offset_bottom: this.model_offset_bottom,
                    index_monitoring_type: this.index_watch_type.toString(),
                    is_mobile: false,
                }
            } else {
                asset = {
                    height: this.local_asset.height,
                    volume: this.local_asset.volume_total,
                    offset: this.model_offset,
                    offset_bottom: this.model_offset_bottom,
                    width: this.local_asset.width,
                    length: this.local_asset.length,
                    tank_type: this.local_asset.type,
                    is_mobile: false,
                    index_monitoring_type: this.index_watch_type.toString(),
                    id_liquid: this.local_asset.asset_liquid.id_asset_liquid,
                    know_model_brand_asset: false,
                }
            }

            asset.asset_name = this.local_asset.name;

            let domicile = {
                route: '', // rue des Godrans
                street_number: '', // 67
                name: '', // rue des Godrans
                locality: '', // Dijon
                country: '', // France
                country_code: '', // FR
                latitude: '', // 47.3223023
                longitude: '', // 5.0381338
                postal_code: null, // 2100
                id_domicile: null,
                address2: !this.selected_address.formatted_address?.includes(this.selected_address.name) ? this.selected_address.name : '',
            }

            if (this.type_address !== 'existing_address') {
                let components = this.selected_address.address_components
                components.forEach((component) => {
                    if (component.types.includes('street_number')) {
                        domicile.street_number = component.long_name
                    } else if (component.types.includes('route')) {
                        domicile.route = component.long_name
                    } else if (component.types.includes('locality')) {
                        domicile.locality = component.long_name
                    } else if (component.types.includes('country')) {
                        domicile.country = component.long_name
                        domicile.country_code = component.short_name
                    } else if (component.types.includes('postal_code')) {
                        domicile.postal_code = component.long_name
                    }
                })

                if (this.show_map) {
                    domicile.latitude = this.selected_address.geometry.location.lat
                    domicile.longitude = this.selected_address.geometry.location.lat
                } else {
                    domicile.latitude = this.selected_address.geometry.location.lat()
                    domicile.longitude = this.selected_address.geometry.location.lng()
                }
            } else {
                domicile = {
                    id_domicile: this.selected_address.id_domicile,
                }
            }

            AssetRepository.putEditAsset(this.local_asset.id_asset, asset, domicile)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.updated'))
                    this.$emit('refresh:tank')
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.closeDialogEditEquipment()
                    this.loading_edit = false
                })
        },
    },
    mounted() {
        //TODO: TEMP
        this.getUserClientDomiciles()
    },
}
</script>

<style>

.pac-target-input {
    background-color: #0000000f;
    padding: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid #7f828a;
}
</style>