import displayHuman from '@/mixin/displayHuman'

export default {
    mixins: [displayHuman],
    methods: {
        getConfigurationDatatableAssetVisionData(asset, model, data) {
            if (!asset.configuration_datatable_asset_vision_data || !JSON.parse(asset.configuration_datatable_asset_vision_data)[model]) {
                return false
            }
            let config = JSON.parse(asset.configuration_datatable_asset_vision_data)[model]

            let result
            switch (data) {
                case 'value':
                    result = config['value_net'] ?? config['volume_net']
                    break
                case 'min':
                    result = config['value_min'] ?? 0
                    break
                case 'max':
                    result = config['value_max'] ?? config['volume_max']
                    break
                case 'percent':
                    if (config['percent']) {
                        result = config['percent']
                    } else if (config['value_net'] && config['value_max']) {
                        result = (config['value_net'] * 100) / config['value_max']
                    } else if (config['volume_net'] && config['volume_max']) {
                        result = (config['volume_net'] * 100) / config['volume_max']
                    }
                    break
                case 'unity':
                    result = config['unity'] ?? 'L'
                    break
                default:
                    result = config[data] ?? '-'
            }

            return typeof result === 'number' ? this.displayToHumanNumberWithSpace(result) : result
        },
    },
}
