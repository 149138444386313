<template>
    <div id="edit-asset-dialog-component">
        <v-dialog v-model="is_edit_asset_dialog_open" max-width="1200">
            <v-card>
                <v-form ref="form">
                    <v-card-title>
                        <p class="text-uppercase font-weight-bold">
                            {{ $t('global_equipment.edit_equipment') }}
                        </p>
                    </v-card-title>

                    <v-card-text>
                        <v-text-field
                            v-model.trim="asset.name"
                            :label="$t('global_equipment.name_equipment')"
                            :rules="[rules.required]"
                            filled
                        />
                        <v-text-field v-model.trim="asset.code_asset" :label="$t('global_equipment.ref_equipment')" filled />
                        <v-text-field v-model.trim="asset.internal_reference" :label="$t('global_equipment.internal_reference')" filled />

                        <v-row>
                            <template v-for="index in asset_module.total_models">
                                <v-col
                                    :key="index"
                                    :lg="12 / Math.min(asset_module.total_models, 4)"
                                    :md="12 / Math.min(asset_module.total_models, 3)"
                                    :sm="12 / Math.min(asset_module.total_models, 2)"
                                    cols="12"
                                >
                                    <p class="font-14 font-weight-bold text-uppercase font-color-medium">
                                        {{ asset_module.name }} {{ index }}
                                    </p>
                                    <asset-model-form-wrapper-component
                                        :asset_liquids="asset_liquids"
                                        :asset_module="asset_module"
                                        :authorize_config_selection="asset_module.total_models > 1"
                                        :column="index - 1"
                                        :configuration="configuration[index - 1]"
                                        v-on:update:model="handleModelConfigurationUpdate"
                                    />
                                </v-col>
                            </template>
                        </v-row>
                    </v-card-text>

                    <v-card-actions class="justify-end">
                        <v-btn text @click="is_edit_asset_dialog_open = false">
                            <span class="font-weight-bold">{{ $t('global.cancel') }}</span>
                        </v-btn>

                        <v-btn
                            :loading="editing_asset"
                            class="font-weight-bold btn-secondary-loader"
                            color="secondary"
                            text
                            @click="prepareUpdate()"
                        >
                            <span class="secondary--text font-weight-bold">{{ $t('global.validate') }}</span>
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import AssetModelFormWrapperComponent from '@/components/Equipment/Generic/form/AssetModelFormWrapperComponent.vue'
import AssetRepository from '@/repositories/AssetRepository'

export default {
    name: 'EditAssetDialogComponent',
    components: { AssetModelFormWrapperComponent },
    data() {
        return {
            is_edit_asset_dialog_open: false,
            editing_asset: false,
            asset: {},
            asset_module: {},
            asset_liquids: [],
            configuration: [],
            models_configurations: [],
            rules: {
                required: (value) => !!value || this.$t('manage_equipment_component.required_field')
            },
        }
    },
    methods: {
        openEditAssetDialog(asset) {
            this.is_edit_asset_dialog_open = true
            this.asset = asset
            this.asset_module = this.$store.getters['asset_module/asset_modules'].find(
                (module) => module.id_module === this.asset.id_module
            )
            this.asset_liquids = this.$store.getters['asset_module/liquids_by_asset_module'](this.asset_module.id_module)
            this.configuration = []
            JSON.parse(this.asset.configuration)?.forEach((config) => {
                this.configuration.push(config)
            })
            //Active model by default when the only model is not required
            if (this.configuration.length === 1 && !this.configuration[0].is_required) {
                this.configuration[0].is_required = true
            }
        },

        handleModelConfigurationUpdate(data) {
            this.models_configurations[data.column] = data.model_configuration
        },

        async prepareUpdate() {
            if (this.$refs.form.validate()) {
                this.editing_asset = true
                let updatedAsset = {
                    asset_models: [],
                    asset_name: this.asset.name,
                    asset_code: this.asset.code_asset,
                    internal_reference: this.asset.internal_reference,
                    contract_number: this.asset.contract_number,
                    installation_date: this.asset.installation_date,
                }

                //Get all columns require
                let empty_columns = Array.from(Array(this.asset_module.total_models).keys())

                this.models_configurations.forEach((model_configuration, column) => {
                    //Push model
                    updatedAsset.asset_models.push({
                        is_required: model_configuration.is_required,
                        id_asset_model: model_configuration.id_asset_model,
                        id_asset_liquid: model_configuration.id_asset_liquid,
                        configuration: model_configuration.configuration,
                    })

                    //Delete already defined column
                    empty_columns.splice(
                        empty_columns.findIndex((index) => index === column),
                        1
                    )
                })

                //Fill empty columns to avoid error on nb model require
                empty_columns.forEach((column) => {
                    updatedAsset.asset_models.splice(column, 0, {
                        is_required: false,
                    })
                })

                this.update(updatedAsset)
            }
        },

        update(updatedAsset) {
            AssetRepository.updateAssetV2(this.asset.id_asset, updatedAsset)
                .then(() => {
                    this.showSnackbar('success', this.$t('global.updated'))
                    this.$store.dispatch('asset_module/handleAssetSoftUpdate', {
                        id_asset: this.asset.id_asset,
                        id_module: this.asset_module.id_module,
                    })
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.is_edit_asset_dialog_open = false
                    this.editing_asset = false
                })
        },
    },
}
</script>
