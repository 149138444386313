<template>
    <div id="chips-last-statement">
        <v-chip :color="getColor()" outlined small>
            <span :class="`${getColor()}--text`"> {{ previous_text }} {{ moment(date).fromNow() }} {{ after_text }} </span>
        </v-chip>
    </div>
</template>

<script>
export default {
    name: 'ChipsLastStatement',
    props: {
        date: {
            type: String,
            require: true,
        },
        danger: {
            type: Number,
            require: true,
        },
        warning: {
            type: Number,
            require: true,
        },
        previous_text: {
            type: String,
        },
        after_text: {
            type: String,
        },
        colorless: {
            type: Boolean
        }
    },
    methods: {
        getColor() {
            if (this.date === '' || this.colorless) {
                return 'disable'
            }

            let color = 'success'
            let dif = Math.abs((new Date(this.date).getTime() - new Date().getTime()) / 1000)
            if (dif > this.danger) {
                color = 'error'
            } else if (dif > this.warning) {
                color = 'warning'
            }
            return color
        },
    },
}
</script>
