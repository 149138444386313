<template>
    <div id="device-traited-data-by-asset-metric-datatable-widget-component">
        <widget-wrapper-component :title="$t('device_traited_data_by_asset_metric_datatable_widget_component.equipment_surveys')">
            <template v-slot:options>
                <menu-actions-component
                    :actions="[
                        {
                            title: $t('device_traited_data_by_asset_metric_datatable_widget_component.download_statements'),
                            icon: 'mdi-download',
                            emit: 'getAssetDataCSVByDeviceNumber',
                            has_access: true,
                        },
                    ]"
                    v-on:getAssetDataCSVByDeviceNumber="getAssetDataCSVByDeviceNumber"
                />
            </template>
            <template v-slot:default>
                <p class="font-color-medium font-12 mb-2 mt-n4">
                    {{ $t('device_traited_data_by_asset_metric_datatable_widget_component.carried_by_device') }} :
                    {{ device.device_number }}
                </p>

                <v-data-table
                    :headers="headers"
                    :items="device_traited_data"
                    :items-per-page="item_per_page"
                    :loading="fetching_dtd"
                    :page="page"
                    :server-items-length="total_rows"
                    loader-height="2"
                    v-on:update:page="handlePageUpdate"
                    v-on:update:items-per-page="handleItemsPerPageUpdate"
                >
                    <template v-slot:item.date_releve="{ item }">
                        <div class="text-no-wrap py-2">
                            {{ item.date_releve }}
                            <chips-last-statement :danger="345600" :date="item.date_releve" :warning="172800" />
                        </div>
                    </template>

                    <template v-slot:item.batterie="{ item }">
                        {{ item.batterie }}
                    </template>

                    <template v-for="header in headers.slice(1, headers.length)" v-slot:[`item.${header.value}`]="{ item }">
                        <div :key="header.value">
                            <template v-if="header.value === 'batterie'">
                                <div class="display-flex-align-center gap-1">
                                    <v-icon class="rotate-90"> mdi-battery</v-icon>
                                    {{ item.batterie }}%
                                </div>
                            </template>

                            <template v-else-if="header.value === 'declencheur'">
                                {{ displayToHumanTrigger(item.declencheur) }}
                            </template>

                            <template v-else-if="header.value === 'quality_network'">
                                <span class="text-no-wrap">
                                    <template v-if="item[header.value] === null">
                                        <v-icon color="disabled">mdi-network-strength-4-cog</v-icon>
                                        {{ $t('global.no_data') }}
                                    </template>

                                    <template v-else-if="item[header.value] === 0 || item[header.value] === 1 || item[header.value] === 2">
                                        <v-icon color="error"> mdi-network-strength-1-alert </v-icon>
                                        {{ $t('global.bad') }}
                                    </template>

                                    <template v-else-if="item[header.value] === 3">
                                        <v-icon color="warning"> mdi-network-strength-3-alert </v-icon>
                                        {{ $t('global.medium') }}
                                    </template>

                                    <template v-else-if="item[header.value] === 4">
                                        <v-icon color="success">mdi-network-strength-3</v-icon>
                                        {{ $t('global.good') }}
                                    </template>

                                    <template v-else-if="item[header.value] === 5">
                                        <v-icon color="success">mdi-network-strength-4</v-icon>
                                        {{ $t('global.very_good') }}
                                    </template>
                                </span>
                            </template>

                            <template v-else>
                                {{ displayToHumanAssetMetricFromCompleteDTd(item, header.value) }}
                            </template>
                        </div>
                    </template>
                </v-data-table>
            </template>
        </widget-wrapper-component>
    </div>
</template>

<script>
import WidgetWrapperComponent from '@/components/Equipment/Generic/Widget/WidgetWrapperComponent.vue'
import DeviceRepository from '@/repositories/DeviceRepository'
import displayHuman from '@/mixin/displayHuman'
import ChipsLastStatement from '@/components/Global/ChipsLastStatement.vue'
import MenuActionsComponent from '@/components/Global/MenuActionsComponent.vue'

export default {
    name: 'DeviceTraitedDataByAssetMetricDatatableWidgetComponent',
    components: { MenuActionsComponent, ChipsLastStatement, WidgetWrapperComponent },
    mixins: [displayHuman],
    props: {
        device: {
            type: Object,
            required: true,
        },
        metrics_configuration: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            headers: [],
            device_traited_data: [],
            fetching_dtd: true,
            downloading_asset_data_csv: true,

            page: 1,
            item_per_page: 5,
            total_rows: 0,
        }
    },
    methods: {
        fetchDeviceTraitedData() {
            this.fetching_dtd = true
            this.device_traited_data = []
            DeviceRepository.getHistoricalByDevice(
                this.device.device_number,
                new URLSearchParams({
                    datatable: true,
                    limit: this.item_per_page,
                    offset: (this.page - 1) * this.item_per_page,
                })
            )
                .then((success) => {
                    this.total_rows = success.data.data.data.total_rows
                    this.device_traited_data = success.data.data.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.fetching_dtd = false
                })
        },

        buildHeadersForAssetMetricsDatatable() {
            this.headers.push({
                text: this.$t('device_traited_data_by_asset_metric_datatable_widget_component.statement_date'),
                value: 'date_releve',
                sortable: false,
            })

            this.metrics_configuration?.metrics?.forEach((metric) => {
                if (metric.is_visible) {
                    this.headers.push({
                        text: `${metric.label} ${metric.unit ? `(${metric.unit})` : ''}`,
                        value: metric.parameters,
                        sortable: false,
                    })
                }
            })

            this.headers.push({
                text: this.$t('device_traited_data_by_asset_metric_datatable_widget_component.battery'),
                value: 'batterie',
                sortable: false,
            })

            this.headers.push({
                text: this.$t('device_traited_data_by_asset_metric_datatable_widget_component.trigger'),
                value: 'declencheur',
                sortable: false,
            })

            this.headers.push({
                text: this.$t('device_traited_data_by_asset_metric_datatable_widget_component.quality_network'),
                value: 'quality_network',
                sortable: false,
            })

            this.fetchDeviceTraitedData()
        },

        handlePageUpdate(page) {
            this.page = page
            this.fetchDeviceTraitedData()
        },

        handleItemsPerPageUpdate(item_per_page) {
            this.item_per_page = item_per_page
            this.fetchDeviceTraitedData()
        },

        getAssetDataCSVByDeviceNumber() {
            this.downloading_asset_data_csv = true
            DeviceRepository.getAssetDataCSVByDeviceNumber(this.device.device_number)
                .then((success) => {
                    let anchorElement = window.document.createElement('a')
                    anchorElement.href = URL.createObjectURL(success.data)
                    anchorElement.target = '_blank'
                    anchorElement.download = `${this.device.device_number}_${this.moment().format(this.date_format)}.csv`
                    document.body.appendChild(anchorElement)
                    anchorElement.click()
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.downloading_asset_data_csv = false
                })
        },
    },
    created() {
        this.buildHeadersForAssetMetricsDatatable()
    },
}
</script>
