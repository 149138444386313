<template>
    <div id="widget-wrapper-component">
        <v-card elevation="0">
            <v-card-text class="font-color-normal">
                <div class="no-drag">
                    <div class="d-flex justify-space-between">
                        <div
                            class="text-uppercase pointer font-16 font-weight-bold font-color-normal"
                            @click="isWidgetOpen = !isWidgetOpen"
                        >
                            {{ title }}
                        </div>

                        <div class="d-flex justify-end gap-2 text-no-wrap">
                            <v-icon @click="isWidgetOpen = !isWidgetOpen">
                                {{isWidgetOpen ? 'mdi-chevron-down' : 'mdi-chevron-up'}}
                            </v-icon>

                            <slot name="options" />
                        </div>
                    </div>

                    <v-expand-transition>
                        <template v-if="isWidgetOpen">
                            <div class="mt-3 font-color-normal">
                                <slot name="default" />
                            </div>
                        </template>
                    </v-expand-transition>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'WidgetWrapperComponent',
    props: {
        title: {
            type: String,
            required: true,
        },
        isAlreadyOpen: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        return {
            isWidgetOpen: this.isAlreadyOpen,
        }
    },
}
</script>
