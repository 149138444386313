<template>
    <div id="asset-localisation-widget-component">
        <v-card class="elevation-0">
            <v-card-title>
                <header-widget-component
                    :is_open="is_open"
                    :title="$t('asset_localisation_widget_component.location')"
                    widget_name="asset-localisation-widget-component"
                />

                <v-expand-transition>
                    <v-card-text v-show="is_open">
                        <template v-if="asset.domicile">
                            <div class="d-flex gap-1 mb-4">
                                <v-icon>mdi-map-marker-outline</v-icon>
                                {{ displayToHumanDomicileAddress(asset.domicile) }}
                            </div>

                            <div class="d-flex gap-1 mb-4">
                                <v-icon>mdi-home</v-icon>
                                {{ asset.domicile.address_name }}
                            </div>

                            <gmap-map
                                :center="{ lat: parseFloat(asset.domicile.latitude), lng: parseFloat(asset.domicile.longitude) }"
                                :zoom="16"
                                class="google-map-container"
                            >
                                <google-map-cluster>
                                    <gmap-marker
                                        :draggable="false"
                                        :position="{
                                            lat: parseFloat(asset.domicile.latitude),
                                            lng: parseFloat(asset.domicile.longitude),
                                        }"
                                    />
                                </google-map-cluster>
                            </gmap-map>
                        </template>

                        <template v-else>
                            {{ $t('asset_localisation_widget_component.no_domicile_associated') }}
                        </template>
                    </v-card-text>
                </v-expand-transition>
            </v-card-title>
        </v-card>
    </div>
</template>

<script>
import GoogleMapCluster from 'vue2-google-maps/dist/components/cluster'
import HeaderWidgetComponent from '@/components/Widget/HeaderWidgetComponent.vue'

export default {
    name: 'AssetLocalisationWidgetComponent',
    components: { HeaderWidgetComponent, GoogleMapCluster },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        is_open: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    methods: {
        displayToHumanDomicileAddress(domicile, message = this.$t('asset_localisation_widget_component.no_associated_address')) {
            if (domicile) {
                return `${domicile.street_number ?? ''} ${domicile.address ?? ''}, ${domicile.postal_code ?? ''} ${domicile.city ?? ''}`
            }

            return message
        }
    }
}
</script>