<template>
    <div id="manage-name-equipment-dialog-component">
        <v-dialog v-model="dialog_manage_name" max-width="500px">
            <v-form ref="form_update_name_asset">
                <v-card>
                    <v-card-title class="pb-0">
                        <p class="text-uppercase font-weight-bold">{{ $t('manage_equipment_component.edit_asset_name') }}</p>
                    </v-card-title>

                    <v-card-text class="pb-0">
                        <v-text-field v-model="name_asset" :label="$t('manage_equipment_component.tank_name')"
                                      :rules="name_asset_rules" filled/>
                    </v-card-text>

                    <v-card-actions class="pt-0 justify-end">
                        <v-btn text @click="dialog_manage_name = false">
                            <span class="font-color-light font-weight-bold">{{ $t('global.cancel') }}</span>
                        </v-btn>

                        <v-btn :loading="loading_edit_name" @click="editAssetName()" class="btn-secondary-loader"
                               color="primary" text
                        >
                            <span class="secondary--text font-weight-bold">{{ $t('global.validate') }}</span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>
import AssetRepository from "../../repositories/AssetRepository";

export default {
    name: "ManageNameEquipmentDialogComponent",
    data() {
        return {
            dialog_manage_name: false,
            loading_edit_name: false,
            id_asset: null,
            name_asset: '',
            name_asset_rules: [
                v => !!v || this.$t('global.field_required'),
            ]
        }
    },
    methods: {
        openManageNameEquipmentDialogComponent(asset) {
            this.id_asset = asset.id_asset;
            this.name_asset = asset.name;
            this.dialog_manage_name = true;
        },

        editAssetName() {
            if (this.$refs.form_update_name_asset.validate()) {
                this.loading_edit_name = true;

                AssetRepository.putEditAssetName(this.id_asset, this.name_asset).then(() => {
                    this.showSnackbar('success', this.$t('manage_equipment_component.edit_asset_name_success'));
                    this.$emit('refresh:tank');
                }).catch(error => {
                    this.manageError(error);
                }).finally(() => {
                    this.dialog_manage_name = false;
                    this.loading_edit_name = false;
                })
            }
        }
    }
}
</script>

<style scoped>

</style>