import axios from '../plugins/axios'
import type { AxiosResponse } from 'axios'
import type { IServerResponse } from '@/models/IServerResponse'

export default {
    getAddressFromGoogleApi(latitude: number, longitude: number): Promise<AxiosResponse<IServerResponse<any>>> {
        return axios.get(
            'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
                latitude +
                ',' +
                longitude +
                '&key=' +
                process.env.VUE_APP_GOOGLE_MAP_KEY,
            {
                transformRequest: (data, headers: any) => {
                    // prevent the header from being added by default
                    delete headers.common['Authorization']
                    // some libraries might set it directly as well, e.g. nuxtjs/auth
                    delete headers['Authorization']
                },
            }
        )
    },
}
