<template>
    <div id="asset-localisation-widget-component">
        <widget-wrapper-component :title="$t('asset_localisation_widget_component.location')">
            <template v-slot:default>
                <template v-if="asset.domicile">
                    <div class="mb-4">
                        <p class="text-uppercase font-color-medium font-14 font-weight-bold mb-2">
                            {{ $t('asset_localisation_widget_component.address') }}
                        </p>

                        <div class="d-flex gap-1 mb-2 font-12">
                            <v-icon>mdi-map-marker-outline</v-icon>
                            {{ displayToHumanDomicileAddress(asset.domicile) }}
                        </div>

                        <div class="d-flex gap-1 mb-2">
                            <v-icon>mdi-home</v-icon>
                            {{ asset.domicile.address_name }}
                        </div>
                    </div>

                    <div
                        v-if="
                            asset.domicile.contact_first_name ||
                            asset.domicile.contact_last_name ||
                            asset.domicile.contact_mail ||
                            asset.domicile.contact_phone ||
                            asset.domicile.contact_note
                        "
                    >
                        <p class="text-uppercase font-color-medium font-weight-bold mb-2">
                            {{ $t('asset_localisation_widget_component.on_site_contact') }}
                        </p>

                        <div class="text-12 d-flex flex-column gap-2 mb-2">
                            <div v-if="asset.domicile.contact_first_name || asset.domicile.contact_last_name" class="flex gap-1">
                                <v-icon class="text-skin-medium">mdi-account</v-icon>
                                <span class="text-skin-default">
                                    {{ asset.domicile.contact_first_name }} {{ asset.domicile.contact_last_name }}
                                </span>
                            </div>

                            <div v-if="asset.domicile.contact_mail" class="d-flex gap-1 mb-2">
                                <v-icon class="text-skin-medium">mdi-at</v-icon>
                                <span class="text-skin-default">{{ asset.domicile.contact_mail }}</span>
                            </div>

                            <div v-if="asset.domicile.contact_phone" class="d-flex gap-1 mb-2">
                                <v-icon class="text-skin-medium">mdi-phone</v-icon>
                                <span class="text-skin-default">{{ asset.domicile.contact_phone }}</span>
                            </div>

                            <div v-if="asset.domicile.contact_note" class="d-flex gap-1 mb-2">
                                <v-icon class="text-skin-medium">mdi-pencil</v-icon>
                                <span class="text-skin-default">{{ asset.domicile.contact_note }}</span>
                            </div>
                        </div>
                    </div>

                    <gmap-map
                        :center="{ lat: parseFloat(asset.domicile.latitude), lng: parseFloat(asset.domicile.longitude) }"
                        :zoom="16"
                        class="google-map-container"
                    >
                        <google-map-cluster>
                            <gmap-marker
                                :draggable="false"
                                :icon="{
                                    url: asset_module.map_marker,
                                    scaledSize: { width: 45, height: 45 },
                                }"
                                :position="{ lat: parseFloat(asset.domicile.latitude), lng: parseFloat(asset.domicile.longitude) }"
                            />
                        </google-map-cluster>
                    </gmap-map>
                </template>

                <template v-else>
                    {{ $t('asset_localisation_widget_component.no_domicile_associated') }}
                </template>
            </template>
        </widget-wrapper-component>
    </div>
</template>

<script>
import displayHuman from '@/mixin/displayHuman'
import WidgetWrapperComponent from '@/components/Equipment/Generic/Widget/WidgetWrapperComponent.vue'
import GoogleMapCluster from 'vue2-google-maps/dist/components/cluster'

export default {
    name: 'AssetLocalisationWidgetComponent',
    components: { GoogleMapCluster, WidgetWrapperComponent },
    mixins: [displayHuman],
    props: {
        asset: {
            type: Object,
            required: true,
        },
        asset_module: {
            type: Object,
            required: true,
        },
    },
}
</script>
