<template>
    <div id="alerts-triggered-widget-component">
        <widget-wrapper-component :title="$t('alerts_triggered_widget_component.alerts_triggered')">
            <template v-slot:options>
                <menu-actions-component
                    :actions="[
                        {
                            title: $t('manage_alert_page.manage_alert'),
                            icon: 'mdi-bell-cog',
                            emit: 'openManageAlertPage',
                            has_access: userHasAccess('BASE', role_vision),
                        },
                    ]"
                    v-on:openManageAlertPage="
                        $router.push({ path: `/equipment/manage_alert/${asset.id_asset}?asset_module=${asset_module.id_module}` })
                    "
                />
            </template>

            <template v-slot:default>
                <template v-if="!fetching_alerts">
                    <template v-if="alerts_triggered.length > 0">
                        <v-row v-for="alert in alerts_triggered" :key="alert.id_alert" class="ma-0 mb-2">
                            <badge-alert-component :alert="alert" v-on:refresh:alert="fetchAlertTriggeredByAsset" />

                            <div class="ml-2">
                                <p class="font-color-medium font-weight-bold font-12 mb-n2 mt-1">
                                    {{ $t('alerts_triggered_widget_component.alerts_triggered') }}
                                </p>
                                <p class="font-14 mb-0">{{ alert.label }}</p>
                            </div>
                        </v-row>
                    </template>

                    <template v-else>
                        <v-alert dense text type="success">
                            {{ $t('alerts_triggered_widget_component.everything_work_fine') }}
                        </v-alert>
                    </template>
                </template>

                <template v-else>
                    <v-skeleton-loader v-for="n in 2" :key="n" loading type="list-item-avatar" />
                </template>
            </template>
        </widget-wrapper-component>
    </div>
</template>

<script>
import WidgetWrapperComponent from '@/components/Equipment/Generic/Widget/WidgetWrapperComponent.vue'
import BadgeAlertComponent from '@/components/Alert/BadgeAlertComponent.vue'
import MenuActionsComponent from '@/components/Global/MenuActionsComponent.vue'

export default {
    name: 'AlertsTriggeredWidgetComponent',
    components: { MenuActionsComponent, BadgeAlertComponent, WidgetWrapperComponent },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        asset_module: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            fetching_alerts: true,
        }
    },
    computed: {
        alerts_triggered() {
            return this.$store.getters['alerts/alertsByAsset'](this.asset).filter((alert) => alert.triggered === true)
        },
    },
    methods: {
        fetchAlertTriggeredByAsset() {
            this.fetching_alerts = true
            this.$store
                .dispatch('alerts/fetchAlertsByAsset', this.asset)
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.fetching_alerts = false
                })
        },
    },
    created() {
        this.fetchAlertTriggeredByAsset()
    },
}
</script>
