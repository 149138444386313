<template>
    <div id="consumption-report-widget-component">
        <widget-wrapper-component :title="$t('consumption_report_widget_component.consumption_report')">
            <template v-slot:default>
                <template v-if="metrics_to_display.length > 0">
                    <date-range-picker-component :date_range="date_range" :loading="fetching_consumption" v-on:update="updateRangeDate" />

                    <p class="font-12 mb-0 font-color-medium">
                        {{ $t('consumption_report_widget_component.interval_in_days') }}:
                        {{ consumptions[id_metric]?.date_interval ?? '-' }}
                    </p>

                    <v-tabs v-model="current_tab" class="mb-4" @change="handleNewCurrentTab">
                        <v-tab v-for="(metric, index) in metrics_to_display" :key="index">
                            <span :class="`${index === current_tab ? 'font-weight-bold primary--text' : ''}`">
                                {{ metric.label }}
                            </span>
                        </v-tab>
                    </v-tabs>

                    <template v-if="!fetching_consumption">
                        <v-slide-y-transition hide-on-leave>
                            <div v-if="!refresh_for_new_tab" class="flex flex-wrap gap-4">
                                <div class="d-flex gap-5">
                                    <!-- VARIATION -->
                                    <v-card class="flex-1-1 align-self-start" elevation="0" outlined>
                                        <v-card-text>
                                            <div class="display-flex-align-center gap-2 mb-4">
                                                <v-icon class="font-color-light">mdi-chart-line</v-icon>
                                                <span class="text-uppercase font-color-light font-weight-bold">
                                                    {{ $t('consumption_report_widget_component.measure') }}
                                                </span>
                                            </div>

                                            <div v-if="consumptions[id_metric].last_value" class="mb-2">
                                                <span class="font-color-light text-uppercase font-12">
                                                    {{ $t('consumption_report_widget_component.current') }}
                                                </span>
                                                <v-divider />
                                                <span>
                                                    {{
                                                        consumptions[id_metric]
                                                            ? displayToHumanNumberWithSpace(consumptions[id_metric].last_value)
                                                            : 0
                                                    }}
                                                    {{ metrics_to_display[current_tab].unit }}
                                                </span>
                                            </div>

                                            <div
                                                v-if="consumptions[id_metric].hasOwnProperty('consumption') && consumptions[id_metric].consumption >= 0"
                                                class="mb-2"
                                            >
                                                <span class="font-color-light text-uppercase font-12">
                                                    {{
                                                        $tc(
                                                            'consumption_report_widget_component.decrease_over_x_days',
                                                            consumptions[id_metric]?.date_interval
                                                        )
                                                    }}
                                                </span>

                                                <v-divider />
                                                <span>
                                                    {{
                                                        consumptions[id_metric]
                                                            ? displayToHumanNumberWithSpace(consumptions[id_metric].consumption)
                                                            : 0
                                                    }}
                                                    {{ metrics_to_display[current_tab].unit }}
                                                </span>
                                            </div>

                                            <div
                                                v-if="
                                                    consumptions[id_metric].hasOwnProperty('average_consumption') &&
                                                    consumptions[id_metric].average_consumption >= 0
                                                "
                                                class="mb-2"
                                            >
                                                <span class="font-color-light text-uppercase font-12">
                                                    {{
                                                        $tc('consumption_report_widget_component.decrease_average_over_x_days',
                                                            consumptions[id_metric]?.date_interval,
                                                        )
                                                    }}
                                                </span>
                                                <v-divider />
                                                <span>
                                                    {{
                                                        consumptions[id_metric]
                                                            ? displayToHumanNumberWithSpace(consumptions[id_metric].average_consumption)
                                                            : 0
                                                    }}
                                                    {{ `${metrics_to_display[current_tab].unit}/j` }}
                                                </span>
                                            </div>

                                            <div
                                                v-if="consumptions[id_metric].hasOwnProperty('filling') && consumptions[id_metric].filling >= 0"
                                                class="mb-2"
                                            >
                                                <span class="font-color-light text-uppercase font-12">
                                                    {{
                                                        $tc('consumption_report_widget_component.increase_over_x_days',
                                                            consumptions[id_metric]?.date_interval,
                                                        )
                                                    }}
                                                </span>
                                                <v-divider />
                                                <span>
                                                    {{
                                                        consumptions[id_metric]
                                                            ? displayToHumanNumberWithSpace(consumptions[id_metric].filling)
                                                            : 0
                                                    }}
                                                    {{ metrics_to_display[current_tab].unit }}
                                                </span>
                                            </div>

                                            <div
                                                v-if="
                                                    consumptions[id_metric].hasOwnProperty('average_filling') && consumptions[id_metric].average_filling >= 0
                                                "
                                                class="mb-2"
                                            >
                                                <span class="font-color-light text-uppercase font-12">
                                                    {{
                                                        $tc('consumption_report_widget_component.increase_average_over_x_days',
                                                            consumptions[id_metric]?.date_interval,
                                                        )
                                                    }}
                                                </span>
                                                <v-divider />
                                                <span>
                                                    {{
                                                        consumptions[id_metric]
                                                            ? displayToHumanNumberWithSpace(consumptions[id_metric].average_filling)
                                                            : 0
                                                    }}
                                                    {{ `${metrics_to_display[current_tab].unit}/j` }}
                                                </span>
                                            </div>
                                        </v-card-text>
                                    </v-card>

                                    <!-- AUTONOMY -->
                                    <v-card
                                        v-if="
                                            consumptions[id_metric]?.day_before_threshold_0 &&
                                            consumptions[id_metric]?.day_before_threshold_20 &&
                                            consumptions[id_metric]?.day_before_threshold_30
                                        "
                                        class="flex-1-1 align-self-start"
                                        elevation="0"
                                        outlined
                                    >
                                        <v-card-text>
                                            <div class="display-flex-align-center gap-2 mb-4">
                                                <v-icon class="font-color-light">mdi-clock-time-five-outline</v-icon>
                                                <span class="text-uppercase font-color-light font-weight-bold">
                                                    {{ $t('consumption_report_widget_component.estimated_autonomy') }}
                                                </span>
                                            </div>

                                            <div class="mb-2">
                                                <span class="font-color-light text-uppercase font-12">
                                                    {{ $t('consumption_report_widget_component.threshold_0') }}
                                                </span>
                                                <v-divider />
                                                <span>
                                                    {{
                                                        $t('consumption_report_widget_component.x_days', [
                                                            consumptions[id_metric]?.day_before_threshold_0,
                                                        ])
                                                    }}
                                                    ({{ moment(consumptions[id_metric]?.date_threshold_0).format(date_format) }})
                                                </span>
                                            </div>

                                            <div class="mb-2">
                                                <span class="font-color-light text-uppercase font-12">
                                                    {{ $t('consumption_report_widget_component.threshold_20') }}
                                                </span>
                                                <v-divider />
                                                <span>
                                                    {{
                                                        $t('consumption_report_widget_component.x_days', [
                                                            consumptions[id_metric]?.day_before_threshold_20,
                                                        ])
                                                    }}
                                                    ({{ moment(consumptions[id_metric]?.date_threshold_20).format(date_format) }})
                                                </span>
                                            </div>

                                            <div class="mb-2">
                                                <span class="font-color-light text-uppercase font-12">
                                                    {{ $t('consumption_report_widget_component.threshold_30') }}
                                                </span>
                                                <v-divider />
                                                <span>
                                                    {{
                                                        $t('consumption_report_widget_component.x_days', [
                                                            consumptions[id_metric]?.day_before_threshold_30,
                                                        ])
                                                    }}
                                                    ({{ moment(consumptions[id_metric]?.day_before_threshold_30).format(date_format) }})
                                                </span>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </div>
                            </div>
                        </v-slide-y-transition>
                    </template>
                </template>

                <template v-else>
                    {{ $t('consumption_report_widget_component.no_consumption_metrics_detected') }}
                </template>
            </template>
        </widget-wrapper-component>
    </div>
</template>

<script>
import WidgetWrapperComponent from '@/components/Equipment/Generic/Widget/WidgetWrapperComponent.vue'
import DateRangePickerComponent from '@/components/Global/DateRangePickerComponent.vue'
import ModuleRepository from '@/repositories/ModuleRepository'
import DisplayHuman from '@/mixin/displayHuman'
import { nextTick } from 'vue'

export default {
    name: 'ConsumptionReportWidgetComponent',
    components: { DateRangePickerComponent, WidgetWrapperComponent },
    mixins: [DisplayHuman],
    props: {
        asset_module: {
            type: Object,
            required: true,
        },
        device: {
            type: Object,
            required: true,
        },
        asset: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            current_tab: 0,

            date_range: {
                startDate: this.moment().clone().startOf('day').subtract(1, 'month').toDate(),
                endDate: this.moment().toDate(),
            },
            fetching_consumption: true,
            refresh_for_new_tab: false,

            is_average: true,
            consumptions: [],
        }
    },
    computed: {
        total_models() {
            return JSON.parse(this.asset_module.configuration_form_asset).total_models || 0
        },
        metrics_to_display() {
            return this.asset_module.default_metrics_configuration.metrics.filter(
                (asset_module) => JSON.parse(asset_module.parameters).displayed_in_widget_consumption
            )
        },
        id_metric() {
            return this.asset_module?.default_metrics_configuration.metrics.findIndex(
                (metric) => metric.parameters === this.metrics_to_display[this.current_tab].parameters
            )
        },
    },
    methods: {
        updateRangeDate(date_range) {
            this.date_range = date_range
            this.fetchDeviceDataConsumptionByIdDevice()
        },

        fetchDeviceDataConsumptionByIdDevice() {
            this.fetching_consumption = true
            ModuleRepository.postDeviceVariationsByIdDevice(this.asset_module.id_module, this.device.id_device, {
                date_begin: this.setDateForRequest(this.date_range.startDate),
                date_end: this.setDateForRequest(this.date_range.endDate),
                is_average: JSON.stringify(this.is_average),
            })
                .then((success) => {
                    this.consumptions = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.fetching_consumption = false
                })
        },

        handleNewCurrentTab() {
            this.refresh_for_new_tab = true
            nextTick(() => {
                this.refresh_for_new_tab = false
            })
        },
    },
    created() {
        this.fetchDeviceDataConsumptionByIdDevice()
    },
}
</script>
