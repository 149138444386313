import axios from '@/plugins/axios'
import type { AxiosResponse } from 'axios'
import type { IServerResponse } from '@/models/IServerResponse'
import type { IModelReference } from '@/models/IModelReference'

const resource = 'api/v1/model_reference'

export default {
    getModelReference(id_brand_reference: number): Promise<AxiosResponse<IServerResponse<IModelReference[]>>> {
        return axios.get(`${resource}/${id_brand_reference}`)
    },
}
