<template>
    <div id="alerts-configured-component">
        <v-card class="elevation-0 overflow-visible">
            <v-card-title>
                <header-widget-component
                    :actions="[
                        {
                            title: $t('list_tank_equipmement_component.manage_alert'),
                            icon: 'mdi-bell-outline',
                            emit: 'goToManageAlertPage',
                            has_access: userHasAccess('BASE', role_vision),
                        },
                    ]"
                    :is_open="is_open"
                    :title="$t('alerts_configured_component.alerts_parameter')"
                    widget_name="alerts-configured-component"
                    v-on:goToManageAlertPage="$router.push('/equipment/manage_alert/' + asset.id_asset)"
                />
            </v-card-title>

            <v-expand-transition>
                <div v-show="is_open">
                    <v-card-text class="pt-0 pb-4">
                        <template v-if="!loading_alerts">
                            <template v-if="alerts.length > 0">
                                <v-row v-for="alert in alerts.filter((x) => x.triggered)" :key="alert.id_alert" class="ma-0 mb-2">
                                    <badge-alert-component :alert="alert" v-on:refresh:alert="prepareFetchAlertsByAsset" />

                                    <div class="ml-2">
                                        <p class="font-color-medium font-weight-bold font-12 mb-n2 mt-1">
                                            {{ $t('alerts_configured_component.alert') }}
                                        </p>
                                        <p class="font-14 mb-0">{{ alert.label }}</p>
                                    </div>
                                </v-row>

                                <v-divider
                                    v-if="alerts.filter((x) => x.triggered).length > 0 && alerts.filter((x) => !x.triggered).length > 0"
                                    class="my-2"
                                />

                                <v-row v-for="alert in alerts.filter((x) => !x.triggered)" :key="alert.id_alert" class="ma-0 mb-2">
                                    <badge-alert-component :alert="alert" v-on:refresh:alert="prepareFetchAlertsByAsset" />

                                    <div class="ml-2">
                                        <p class="font-color-medium font-weight-bold font-12 mb-n2 mt-1">
                                            {{ $t('alerts_configured_component.alert') }}
                                        </p>
                                        <p class="font-14 mb-0">{{ alert.label }}</p>
                                    </div>
                                </v-row>
                            </template>

                            <template v-else>
                                <p class="mb-1 text-center">
                                    {{ $t('alerts_configured_component.no_alert_set') }}
                                </p>
                            </template>
                        </template>

                        <v-skeleton-loader v-for="n in 2" v-else :key="n" loading type="list-item-avatar" />
                    </v-card-text>
                </div>
            </v-expand-transition>
        </v-card>
    </div>
</template>

<script>
import HeaderWidgetComponent from './HeaderWidgetComponent'
import DeviceRepository from '@/repositories/DeviceRepository'
import BadgeAlertComponent from '@/components/Alert/BadgeAlertComponent.vue'

export default {
    name: 'AlertsConfiguredComponent',
    components: { BadgeAlertComponent, HeaderWidgetComponent },
    created() {
        this.prepareFetchAlertsByAsset()
    },
    props: {
        is_open: {
            type: Boolean,
            required: false,
            default: false,
        },
        asset: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            alerts: [],
            loading_alerts: false,
        }
    },
    methods: {
        async prepareFetchAlertsByAsset() {
            this.loading_alerts = true
            this.alerts = []
            await Promise.all(
                this.asset.devices.map((device) => {
                    return Promise.resolve(this.fetchAlertsByDevice(device.id_device))
                })
            ).finally(() => {
                this.loading_alerts = false
            })
        },
        fetchAlertsByDevice(id_device) {
            return DeviceRepository.getAllAlertByIdDevice(id_device)
                .then((success) => {
                    success.data.data.forEach((alert) => {
                        this.alerts.push(alert)
                    })
                })
                .catch((error) => {
                    this.manageError(error)
                })
        },
    },
}
</script>
