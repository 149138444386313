<template>
    <div id="list-asset-component" class="pa-4">
        <p class="font-weight-bold mb-4">
            {{
                $t('list_asset_component.assets_length', [
                    fetching_asset_module_data ? '-' : assets.length,
                    $t(`modules.${asset_module.designation}`).toLowerCase(),
                ])
            }}
        </p>

        <template v-if="!fetching_asset_module_data">
            <v-row class="ma-0">
                <v-col v-for="(asset, index) in assets" :key="asset.id_asset" class="mt-2" cols="4" lg="3" md="4" xl="2">
                    <div class="h-100" @click="displayDetailAsset(index)">
                        <asset-generic-card-minimalist-component
                            :asset="asset"
                            :asset_module="asset_module"
                            :is_selected="index === index_asset_selected"
                        />
                    </div>
                </v-col>
            </v-row>

            <div v-if="assets[index_asset_selected] && !$store.getters['asset_module/refresh']">
                <asset-details-component :asset="assets[index_asset_selected]" :asset_module="asset_module" />
            </div>

            <template v-else>
                <empty-page-component
                    :link_img="'/images/error/empty_page.png'"
                    :main_text="`Pas de ${$t(`modules.${asset_module.designation}`).toLowerCase()} pour le moment`"
                />
            </template>
        </template>

        <template v-else>
            <v-row>
                <v-col v-for="n in 2" :key="n" class="mt-2" cols="4" lg="3" md="4" xl="2">
                    <div class="h-100">
                        <v-skeleton-loader v-if="is_mobile" type="text" />
                        <v-skeleton-loader v-else type="image" />
                    </div>
                </v-col>
            </v-row>

            <v-row class="mt-8">
                <v-col v-for="n in 4" :key="n" cols="12" lg="6">
                    <v-skeleton-loader type="card-heading, list-item-two-line, image, table-thead" />
                </v-col>
            </v-row>
        </template>

        <v-speed-dial
            v-if="
                assets[index_asset_selected] &&
                (userHasAccess(asset_module.designation, this.role_vision) || userHasAccess('BASE', this.role_vision))
            "
            v-model="is_settings_open"
            :class="is_mobile ? 'bottom-left-btn-mobile' : 'bottom-left-btn-desktop'"
            direction="top"
            transition="slide-y-reverse-transition"
        >
            <template v-slot:activator>
                <v-btn v-model="is_settings_open" color="secondary" elevation="0" fab>
                    <v-icon v-if="is_settings_open" color="primary"> mdi-close</v-icon>

                    <v-icon v-else color="primary"> mdi-cog</v-icon>
                </v-btn>
            </template>

            <v-btn v-if="userHasAccess(asset_module.designation, this.role_edit)" color="primary" elevation="0" fab @click="openEditAssetDialog">
                <v-icon color="secondary">mdi-pencil</v-icon>
            </v-btn>

            <v-btn
                v-if="userHasAccess('BASE', this.role_vision)"
                :to="{ path: `/equipment/manage_alert/${assets[index_asset_selected]?.id_asset}?asset_module=${asset_module.id_module}` }"
                color="primary"
                data-cy="manage-alert-btn"
                elevation="0"
                fab
            >
                <v-icon color="secondary">mdi-bell-cog</v-icon>
            </v-btn>
        </v-speed-dial>

        <edit-asset-dialog-component ref="editAssetDialog" />
    </div>
</template>

<script>
import AssetDetailsComponent from '@/components/Equipment/Generic/AssetDetailsComponent.vue'
import AssetGenericCardMinimalistComponent from '@/components/Equipment/Generic/AssetGenericCardMinimalistComponent.vue'
import EmptyPageComponent from '@/components/Error/EmptyPageComponent.vue'
import EditAssetDialogComponent from '@/components/Equipment/Generic/form/EditAssetDialogComponent.vue'

export default {
    name: 'ListAssetComponent',
    components: {
        EditAssetDialogComponent,
        EmptyPageComponent,
        AssetGenericCardMinimalistComponent,
        AssetDetailsComponent,
    },
    props: {
        asset_module: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            fetching_asset_module_data: true,
            index_asset_selected: 0,
            assets: [],

            is_settings_open: false,
        }
    },
    methods: {
        async fetchAssetModulesData() {
            this.fetching_asset_module_data = true
            await Promise.all([
                this.$store.dispatch('asset_module/fetchAssetsOfAssetModule', this.asset_module.id_module),
                this.$store.dispatch('asset_module/fetchAssetLiquidByIdModule', {
                    id_pro: this.$store.getters['user_connected/user_connected'].id_professional,
                    id_module: this.asset_module.id_module,
                }),
            ])

            this.assets = this.$store.getters['asset_module/assets_by_asset_module'](this.asset_module.id_module)
            this.fetching_asset_module_data = false
        },

        displayDetailAsset(index) {
            this.index_asset_selected = index
            this.$store.commit('asset_module/refreshAssetPage')
        },
        openEditAssetDialog() {
            this.$refs.editAssetDialog.openEditAssetDialog(this.assets[this.index_asset_selected])
        },
    },
    created() {
        this.fetchAssetModulesData()
    },
}
</script>
