import axios from '@/plugins/axios'
import type { AxiosResponse } from 'axios'
import type { IServerResponse } from '@/models/IServerResponse'
import type { IBrandReference } from '@/models/IBrandReference'

const resource = 'api/v1/brand_reference'

export default {
    geBrandReference(type_equipment: string): Promise<AxiosResponse<IServerResponse<IBrandReference[]>>> {
        return axios.get(resource, { params: { type: type_equipment } })
    },
}
