<template>
    <div id="note-devices-widget-component">
        <widget-wrapper-component :title="$t('note_devices_widget_component.device_note')">
            <template v-slot:default>
                <template v-if="devices.length > 0">
                    <v-tabs v-model="selected_device" class="mb-2" v-on:change="handleDeviceSelectUpdate">
                        <v-tab
                            v-for="(device, index) in devices"
                            :key="device.id_device"
                            :class="selected_device === index ? 'font-weight-bold' : ''"
                            :value="index"
                        >
                            {{ device.device_number }}
                        </v-tab>
                    </v-tabs>

                    <v-slide-x-transition hide-on-leave>
                        <div v-if="!refresh" class="mt-4 text-sm">
                            <v-textarea v-model="device.note" :placeholder="$t('note_devices_widget_component.no_notes')" filled readonly />
                        </div>
                    </v-slide-x-transition>
                </template>

                <template v-else>
                    {{ $t('note_devices_widget_component.no_device_associated') }}
                </template>
            </template>
        </widget-wrapper-component>
    </div>
</template>

<script>
import WidgetWrapperComponent from '@/components/Equipment/Generic/Widget/WidgetWrapperComponent.vue'
import { nextTick } from 'vue'

export default {
    name: 'NoteDevicesWidgetComponent',
    components: { WidgetWrapperComponent },
    props: {
        devices: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            selected_device: 0,
            refresh: false,
        }
    },
    computed: {
        device() {
            return this.devices[this.selected_device]
        },
    },
    methods: {
        handleDeviceSelectUpdate() {
            this.refresh = true
            nextTick(() => {
                this.refresh = false
            })
        },
    },
}
</script>
