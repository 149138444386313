<template>
    <div id="image-asset-widget-component">
        <WidgetWrapperComponent :title="asset.name">
            <template v-slot:default>
                <template v-if="!loading_images">
                    <template v-if="images.length === 0">
                        <span>{{ $t('image_assets_widget_component.no_image_associate') }}</span>
                    </template>

                    <v-carousel v-else :height="!is_mobile ? 300 : 150" :show-arrows="images.length > 1" hide-delimiters>
                        <v-carousel-item v-for="(image, index) in images" :key="index">
                            <v-img :max-height="!is_mobile ? 300 : 150" :src="image" contain />
                        </v-carousel-item>
                    </v-carousel>
                </template>

                <template v-else>
                    <v-skeleton-loader type='image'/>
                </template>
            </template>
        </WidgetWrapperComponent>
    </div>
</template>

<script>
import WidgetWrapperComponent from '@/components/Equipment/Generic/Widget/WidgetWrapperComponent.vue'
import AssetRepository from '@/repositories/AssetRepository'

export default {
    name: 'ImageAssetWidgetComponent',
    components: { WidgetWrapperComponent },
    props: {
        asset: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading_images: true,
            images: [],
        }
    },
    methods: {
        fetchAssetImages() {
            this.loading_images = true
            AssetRepository.getAssetImages(this.asset.id_asset)
                .then((success) => {
                    this.images = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_images = false
                })
        },
    },
    created() {
        this.fetchAssetImages()
    },
}
</script>
