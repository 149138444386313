<template>
    <svg fill="none" height="31" viewBox="0 0 45 31" width="45" xmlns="http://www.w3.org/2000/svg">
        <path
            class="background-svg"
            clip-rule="evenodd"
            d="M26.3797 0C26.7939 0 27.1297 0.335786 27.1297 0.75V2.89893H29.3635C29.7777 2.89893 30.1135 3.23472 30.1135 3.64893V20.171C30.1135 20.3219 30.069 20.4623 29.9924 20.5799L28.6556 22.8513L30.0947 29.3719C30.184 29.7764 29.9284 30.1767 29.524 30.2659C29.1195 30.3552 28.7192 30.0997 28.63 29.6952L27.5385 24.7495L26.1199 27.1599C25.9852 27.3889 25.7393 27.5295 25.4736 27.5295H19.6394C19.3737 27.5295 19.1278 27.3889 18.993 27.1599L17.5742 24.749L16.4826 29.6952C16.3933 30.0997 15.9931 30.3552 15.5886 30.2659C15.1841 30.1767 14.9286 29.7764 15.0178 29.3719L16.457 22.8508L15.1132 20.5673C15.0631 20.487 15.0279 20.3965 15.0112 20.2996C15.0021 20.2475 14.9985 20.1946 15.0006 20.1419V3.64893C15.0006 3.23472 15.3363 2.89893 15.7506 2.89893H25.6297V0.75C25.6297 0.335786 25.9655 0 26.3797 0ZM17.062 20.9213L20.0683 26.0295H25.0447L28.051 20.9213H17.062ZM28.6135 19.4213H16.5006V4.39893H28.6135V19.4213Z"
            fill="white"
            fill-rule="evenodd"
        />
    </svg>
</template>

<script>
export default {
    name: 'SiloSvg',
}
</script>
