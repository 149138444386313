<template>
    <div id="information-asset-component">
        <v-card class="elevation-0">
            <v-card-title>
                <header-widget-component
                    :is_open="is_open"
                    :title="$t('information_asset_component.title')"
                    widget_name="information-asset-component"
                />
            </v-card-title>

            <v-expand-transition>
                <div v-show="is_open">
                    <v-card-text class="pt-2 pb-4">
                        <v-row class="pb-4" data-cy="row-brand-reference">
                            <v-col v-if="asset.brand_reference" class="pb-0 pt-0 text-center" cols="4">
                                <span class="font-12 font-color-light">
                                    {{ $t('information_asset_component.brand') }}
                                </span>
                                <p class="asset-information">
                                    {{ asset.brand_reference ? asset.brand_reference.name : $t('global.not_specified') }}
                                </p>
                            </v-col>

                            <v-divider v-if="asset.brand_reference" color="white" vertical />

                            <v-col :cols="asset.brand_reference ? 4 : 6" class="pb-0 pt-0 text-center">
                                <span class="font-12 font-color-light">
                                    {{ $t('information_asset_component.model') }}
                                </span>
                                <p class="asset-information">
                                    {{ asset.brand_reference ? asset.model_reference.trade_name : displayToHumanTypeAsset(asset.type) }}
                                </p>
                            </v-col>

                            <v-divider color="white" vertical />

                            <v-col :cols="asset.brand_reference ? 4 : 6" class="pb-0 pt-0 text-center">
                                <span class="font-12 font-color-light">
                                    {{ $t('information_asset_component.liquid') }}
                                </span>
                                <p class="asset-information">{{ asset.asset_liquid.name }}</p>
                            </v-col>
                        </v-row>

                        <v-row v-if="asset.brand_reference === null" class="pb-2" data-cy="unknown-brand-reference-info">
                            <v-col class="pb-0 pt-0 text-center" cols="3">
                                <span class="font-12 font-color-light">
                                    {{ $t('information_asset_component.capacity') }}
                                </span>
                                <p class="asset-information">{{ numberWithSpaces(Math.round(asset.volume_total)) }} L</p>
                            </v-col>

                            <v-divider color="white" vertical />

                            <v-col class="pb-0 pt-0 text-center" cols="3">
                                <span class="font-12 font-color-light">
                                    {{ $t('information_asset_component.height') }}
                                </span>
                                <p class="asset-information">{{ asset.height }} cm</p>
                            </v-col>

                            <v-divider color="white" vertical />

                            <v-col class="pb-0 pt-0 text-center">
                                <span class="font-12 font-color-light">
                                    {{ $t('information_asset_component.length') }}
                                </span>
                                <p class="asset-information">{{ asset.length }} cm</p>
                            </v-col>

                            <v-divider color="white" vertical />

                            <v-col class="pb-0 pt-0 text-center">
                                <span class="font-12 font-color-light">
                                    {{ $t('information_asset_component.width') }}
                                </span>
                                <p class="asset-information">{{ asset.width }} cm</p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </div>
            </v-expand-transition>
        </v-card>
    </div>
</template>

<script>
import HeaderWidgetComponent from './HeaderWidgetComponent'

export default {
    name: 'InformationAssetComponent',
    components: { HeaderWidgetComponent },
    props: {
        is_open: {
            type: Boolean,
            required: false,
            default: false,
        },
        asset: {
            type: Object,
            required: true,
            default: function () {
                return {
                    model_reference: '',
                    asset_liquid: { name: '' },
                    volume: null,
                    height: null,
                    length: null,
                    width: null,
                }
            },
        },
    },
    methods: {
        displayToHumanTypeAsset(type) {
            switch (type) {
                case 1:
                    return this.$t('information_asset_component.rectangular')
                case 2:
                    return this.$t('information_asset_component.cylindrical_lying')
                case 3:
                    return this.$t('information_asset_component.vertical_cylindrical')
                case 4:
                    return this.$t('information_asset_component.cylindrical_lying')
                case 5:
                    return 'IBC'
                default:
                    return this.$t('global.not_specified')
            }
        },
    },
}
</script>
