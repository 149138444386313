<template>
    <div id="header-widget-component">
        <div class="d-flex align-center">
            <span class="font-16 pointer add-space-after mr-14" @click="openOrCloseWidgetEquipment(widget_name)">
                {{ title }}
            </span>
            <div class="btn-open-setting-widget">
                <v-icon @click="openOrCloseWidgetEquipment(widget_name)">
                    {{ is_open ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                </v-icon>

                <v-menu offset-x offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon data-cy="widget-option-btn" v-bind="attrs" @click="bottom_sheet = true" v-on="on">
                            mdi-dots-vertical
                        </v-icon>
                    </template>

                    <v-list v-if="!is_mobile">
                        <section v-for="action in actions" :key="action.title">
                            <v-list-item v-if="action.has_access" :data-cy="action.data_cy" @click="$emit(action.emit)">
                                <v-list-item-icon class="my-auto mr-6">
                                    <v-icon>{{ action.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title class="my-0">
                                    <span>{{ action.title }}</span>
                                </v-list-item-title>
                            </v-list-item>
                        </section>

                        <v-list-item data-cy="delete-widget-btn" @click="deleteWidgetEquipment(widget_name)">
                            <v-list-item-icon class="my-auto mr-6">
                                <v-icon color="error">mdi-trash-can</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title class="my-0">
                                <span class="error--text">{{ $t('header_widget_component.delete_widget') }}</span>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>

            <v-bottom-sheet v-if="is_mobile" v-model="bottom_sheet">
                <v-sheet v-touch:swipe.bottom="swipeBottomHandler">
                    <v-list>
                        <section v-for="action in actions" :key="action.title">
                            <v-list-item v-if="action.has_access" @click="$emit(action.emit)">
                                <v-list-item-icon class="my-auto mr-6">
                                    <v-icon>{{ action.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title class="my-0">
                                    <span>{{ action.title }}</span>
                                </v-list-item-title>
                            </v-list-item>
                        </section>

                        <v-list-item data-cy="delete-widget-btn" @click="deleteWidgetEquipment(widget_name)">
                            <v-list-item-icon class="my-auto mr-6">
                                <v-icon color="error">mdi-trash-can</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title class="my-0">
                                <span class="error--text">{{ $t('header_widget_component.delete_widget') }}</span>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-sheet>
            </v-bottom-sheet>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderWidgetComponent',
    props: {
        title: {
            type: String,
            required: true,
        },
        is_open: {
            type: Boolean,
            required: true,
        },
        widget_name: {
            type: String,
            required: true,
        },
        actions: {
            type: Array,
            required: false,
        },
    },
    data() {
        return {
            bottom_sheet: false,
        }
    },
    methods: {
        swipeBottomHandler() {
            this.bottom_sheet = false
        },

        openOrCloseWidgetEquipment(component_title) {
            let order_configuration_equipment = this.$store.getters['page_order_configuration/order_configuration_equipment']
            order_configuration_equipment.filter((configuration) => {
                if (configuration.widget_name === component_title) {
                    configuration.is_open = !configuration.is_open
                }
            })

            this.updateOrCreatePageOrderConfiguration(order_configuration_equipment, null)
        },

        deleteWidgetEquipment(widget_name) {
            let order_configuration_equipment = this.$store.getters['page_order_configuration/order_configuration_equipment']
            order_configuration_equipment.forEach((config) => {
                if (config.widget_name === widget_name) {
                    config.is_display = false
                }
            })

            this.updateOrCreatePageOrderConfiguration(
                order_configuration_equipment,
                this.$t('header_widget_component.success_delete_widget')
            )
        },
    },
}
</script>

<style>
.btn-open-setting-widget {
    position: absolute !important;
    right: 15px !important;
    top: 10px !important;
}

.add-space-after:after {
    content: '\00a0';
}
</style>