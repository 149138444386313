<template>
    <div id="alerts-configured-widget-component">
        <widget-wrapper-component :title="$t('alerts_configured_widget_component.configured_alerts')">
            <template v-slot:options>
                <menu-actions-component
                    :actions="[
                        {
                            title: $t('manage_alert_page.manage_alert'),
                            icon: 'mdi-bell-cog',
                            emit: 'openManageAlertPage',
                            has_access: userHasAccess('BASE', role_vision),
                        },
                    ]"
                    v-on:openManageAlertPage="
                        $router.push({ path: `/equipment/manage_alert/${asset.id_asset}?asset_module=${asset_module.id_module}` })
                    "
                />
            </template>

            <template v-slot:default>
                <template v-if="!loading_alerts">
                    <template v-if="alerts.length > 0">
                        <v-row v-for="alert in alerts.filter((x) => x.triggered)" :key="alert.id_alert" class="ma-0 mb-2">
                            <badge-alert-component :alert="alert" v-on:refresh:alert="fetchAlertsByAsset" />

                            <div class="ml-2">
                                <p class="font-color-medium font-weight-bold font-12 mb-n2 mt-1">
                                    {{ $t('alert_card_component.alert_if') }}
                                </p>
                                <p class="font-14 mb-0">{{ alert.label }}</p>
                            </div>
                        </v-row>

                        <v-divider
                            v-if="alerts.filter((x) => x.triggered).length > 0 && alerts.filter((x) => !x.triggered).length > 0"
                            class="my-2"
                        />

                        <v-row v-for="alert in alerts.filter((x) => !x.triggered)" :key="alert.id_alert" class="ma-0 mb-2">
                            <badge-alert-component :alert="alert" v-on:refresh:alert="fetchAlertsByAsset" />

                            <div class="ml-2">
                                <p class="font-color-medium font-weight-bold font-12 mb-n2 mt-1">
                                    {{ $t('alert_card_component.alert_if') }}
                                </p>
                                <p class="font-14 mb-0">{{ alert.label }}</p>
                            </div>
                        </v-row>
                    </template>

                    <template v-else>
                        {{ $t('alerts_configured_widget_component.no_configured_alerts') }}
                    </template>
                </template>

                <v-skeleton-loader v-for="n in 2" v-else :key="n" loading type="list-item-avatar" />
            </template>
        </widget-wrapper-component>
    </div>
</template>

<script>
import BadgeAlertComponent from '@/components/Alert/BadgeAlertComponent.vue'
import WidgetWrapperComponent from '@/components/Equipment/Generic/Widget/WidgetWrapperComponent.vue'
import MenuActionsComponent from '@/components/Global/MenuActionsComponent.vue'

export default {
    name: 'AlertsConfiguredWidgetComponent',
    components: { MenuActionsComponent, WidgetWrapperComponent, BadgeAlertComponent },
    created() {
        this.fetchAlertsByAsset()
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        asset_module: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            selected_alert: {
                user_preference: null,
                configuration: [],
                configuration_appearance: {},
            },
            loading_alerts: false,
        }
    },
    computed: {
        alerts() {
            return this.$store.getters['alerts/alertsByAsset'](this.asset)
        },
    },
    methods: {
        fetchAlertsByAsset() {
            this.loading_alerts = true
            this.$store
                .dispatch('alerts/fetchAlertsByAsset', this.asset)
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_alerts = false
                })
        },

        openDialogUpdateAlert(id_alert) {
            this.selected_alert = this.alerts.find((x) => x.id_alert === id_alert)
            this.$refs.DialogUpdateAlertComponent.dialog_edit_alert = true
        },
    },
}
</script>
