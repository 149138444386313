var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"alerts-triggered-widget-component"}},[_c('widget-wrapper-component',{attrs:{"title":_vm.$t('alerts_triggered_widget_component.alerts_triggered')},scopedSlots:_vm._u([{key:"options",fn:function(){return [_c('menu-actions-component',{attrs:{"actions":[
                    {
                        title: _vm.$t('manage_alert_page.manage_alert'),
                        icon: 'mdi-bell-cog',
                        emit: 'openManageAlertPage',
                        has_access: _vm.userHasAccess('BASE', _vm.role_vision),
                    },
                ]},on:{"openManageAlertPage":function($event){return _vm.$router.push({ path: `/equipment/manage_alert/${_vm.asset.id_asset}?asset_module=${_vm.asset_module.id_module}` })}}})]},proxy:true},{key:"default",fn:function(){return [(!_vm.fetching_alerts)?[(_vm.alerts_triggered.length > 0)?_vm._l((_vm.alerts_triggered),function(alert){return _c('v-row',{key:alert.id_alert,staticClass:"ma-0 mb-2"},[_c('badge-alert-component',{attrs:{"alert":alert},on:{"refresh:alert":_vm.fetchAlertTriggeredByAsset}}),_c('div',{staticClass:"ml-2"},[_c('p',{staticClass:"font-color-medium font-weight-bold font-12 mb-n2 mt-1"},[_vm._v(" "+_vm._s(_vm.$t('alerts_triggered_widget_component.alerts_triggered'))+" ")]),_c('p',{staticClass:"font-14 mb-0"},[_vm._v(_vm._s(alert.label))])])],1)}):[_c('v-alert',{attrs:{"dense":"","text":"","type":"success"}},[_vm._v(" "+_vm._s(_vm.$t('alerts_triggered_widget_component.everything_work_fine'))+" ")])]]:_vm._l((2),function(n){return _c('v-skeleton-loader',{key:n,attrs:{"loading":"","type":"list-item-avatar"}})})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }