var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"information-asset-widget-component"}},[_c('widget-wrapper-component',{attrs:{"title":_vm.$t('information_asset_widget_component.general_view')},scopedSlots:_vm._u([{key:"options",fn:function(){return [_c('menu-actions-component',{attrs:{"actions":[
                    {
                        title: _vm.$t('global_equipment.edit_equipment'),
                        icon: 'mdi-pencil',
                        emit: 'openEditAssetDialog',
                        has_access: _vm.userHasAccess(_vm.asset_module.designation, _vm.role_edit),
                    },
                ]},on:{"openEditAssetDialog":_vm.openEditAssetDialog}})]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"information-asset-widget-container",attrs:{"id":"information-asset-widget-container"}},[_vm._l((_vm.total_models),function(n,index){return [_c('div',{key:n,staticClass:"d-flex flex-column gap-2 position-relative"},[(_vm.configuration[index]['is_required'])?[_c('div',{staticClass:"d-flex justify-center"},[_c('display-asset-content-level-component',{attrs:{"asset":_vm.asset,"asset_module":_vm.asset_module,"index_model":index,"scale":0.7}})],1),(_vm.configuration[index]['model_name'])?_c('div',[_c('p',{staticClass:"mb-1 font-12 font-color-medium"},[_vm._v(" "+_vm._s(_vm.$t('information_asset_widget_component.model_name'))+" ")]),_c('p',{staticClass:"pl-4 font-16 font-color-normal"},[_vm._v(" "+_vm._s(_vm.configuration[index]['model_name'])+" ")])]):_vm._e(),_c('div',[_c('p',{staticClass:"mb-1 font-12 font-color-medium"},[_vm._v(" "+_vm._s(_vm.$t('information_asset_widget_component.content'))+" ")]),_c('p',{staticClass:"pl-4 font-16 font-color-normal"},[_vm._v(" "+_vm._s(_vm.$store.getters['asset_module/liquid_by_id_module_and_id_asset_and_model']( _vm.asset_module.id_module, _vm.asset.id_asset, index ).name)+" ")])]),_c('div',[_c('p',{staticClass:"mb-1 font-12 font-color-medium"},[_vm._v(" "+_vm._s(_vm.$t('information_asset_widget_component.quantity'))+" ")]),_c('div',{staticClass:"pl-4"},[_c('div',{staticClass:"d-flex gap-1 font-color-normal"},[(_vm.getConfigurationDatatableAssetVisionData(_vm.asset, index, 'value'))?_c('span',{staticClass:"font-weight-bold font-16"},[_vm._v(" "+_vm._s(_vm.getConfigurationDatatableAssetVisionData(_vm.asset, index, 'value'))+" "+_vm._s(_vm.getConfigurationDatatableAssetVisionData(_vm.asset, index, 'unity'))+" ")]):_vm._e(),(
                                                _vm.getConfigurationDatatableAssetVisionData(_vm.asset, index, 'value') &&
                                                _vm.getConfigurationDatatableAssetVisionData(_vm.asset, index, 'max')
                                            )?[_vm._v(" / ")]:_vm._e(),(_vm.getConfigurationDatatableAssetVisionData(_vm.asset, index, 'max'))?_c('span',{staticClass:"font-14"},[_vm._v(" "+_vm._s(_vm.getConfigurationDatatableAssetVisionData(_vm.asset, index, 'max'))+" "+_vm._s(_vm.getConfigurationDatatableAssetVisionData(_vm.asset, index, 'unity'))+" ")]):_vm._e()],2),(_vm.getConfigurationDatatableAssetVisionData(_vm.asset, index, 'percent'))?_c('div',{staticClass:"font-weight-bold font-12 font-italic font-color-normal"},[_vm._v(" "+_vm._s(_vm.getConfigurationDatatableAssetVisionData(_vm.asset, index, 'percent'))+" % ")]):_vm._e()])])]:[_c('v-icon',{staticClass:"font-color-light"},[_vm._v(" mdi-cancel")])]],2)]})],2)]},proxy:true}])}),_c('edit-asset-dialog-component',{ref:"editAssetDialog"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }