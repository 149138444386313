<template>
    <div v-if="device.is_subscription_active" id="information-device-component">
        <v-card class="elevation-0">
            <v-card-title>
                <header-widget-component
                    :is_open="is_open"
                    :title="$t('information_device_component.title', [device.device_number])"
                    widget_name="information-device-component"
                />
            </v-card-title>

            <v-expand-transition>
                <div v-show="is_open" class="hidden-information">
                    <div class="information">
                        <v-card-text class="pt-0 pb-4">
                            <span class="font-color-light">
                                <v-icon class="mr-1" dense>mdi-access-point</v-icon>
                                {{ $t('information_device_component.network') }} :
                            </span>
                            <span class="font-weight-bold">{{ device.network_display }}</span>
                            <br />

                            <div class="my-1">
                                <span class="font-color-light">
                                    <v-icon class="mr-1" dense>mdi-check-network-outline</v-icon>
                                    {{ $t('information_device_component.total_statement_per_day') }} :
                                </span>
                                <span class="font-weight-bold">{{ device.total_statement_per_day }}</span>
                                <br />
                            </div>

                            <div class="my-1">
                                <v-row class="ma-0">
                                    <span class="font-color-light">
                                        <v-icon class="mr-1" dense>mdi-access-point-network</v-icon>
                                        {{ $t('information_device_component.date_last_emission') }} :
                                    </span>
                                    <chips-last-statement
                                        :danger="345600"
                                        :date="device.date_last_emission"
                                        :warning="172800"
                                        class="ml-1"
                                    />
                                </v-row>
                            </div>

                            <div class="my-1">
                                <span class="font-color-light">
                                    <v-icon class="mr-1" dense>mdi-battery-70</v-icon>
                                    {{ $t('information_device_component.battery') }} :
                                </span>
                                <span v-if="device.battery" class="font-weight-bold">{{ device.battery }} %</span>
                                <v-icon v-else dense> mdi-battery-unknown</v-icon>
                                <br />
                            </div>

                            <div v-if="device.is_bug_hardware" class="my-1">
                                <span class="font-color-light warning--text">
                                    <v-icon class="mr-1 warning--text" dense>mdi-alert-outline</v-icon>
                                    {{ $t('information_device_component.bug_device_detected') }}
                                </span>
                            </div>

                            <div class="my-1">
                                <template v-if="device.orientation">
                                    <span class="font-color-light">
                                        <v-icon class="mr-1" dense>mdi-compass-outline</v-icon>
                                        {{ $t('information_device_component.orientation') }} :
                                    </span>
                                    <span class="font-weight-bold">{{ displayToHumanOrientation(device.orientation) }}</span>
                                    <br />
                                </template>
                            </div>

                            <div
                                v-if="
                                    device.is_sentinel_active ||
                                    device.is_threshold_low_active ||
                                    device.is_threshold_high_active ||
                                    device.is_integrated_device ||
                                    device.is_treatment_adblue
                                "
                                class="my-1"
                            >
                                <span class="font-color-light">
                                    <v-icon class="mr-1" dense>mdi-cogs</v-icon>
                                    {{ $t('information_device_component.activated_options') }} :
                                    <br />
                                </span>
                                <span v-if="device.is_sentinel_active" class="font-weight-bold ml-8">
                                    - {{ $t('information_device_component.sentinel_mode') }}
                                    <br />
                                </span>
                                <span v-if="device.is_threshold_low_active" class="font-weight-bold ml-8">
                                    - {{ $t('information_device_component.low_threshold') }}
                                    <br />
                                </span>
                                <span v-if="device.is_threshold_high_active" class="font-weight-bold ml-8">
                                    - {{ $t('information_device_component.high_threshold') }}
                                    <br />
                                </span>
                                <span v-if="device.is_integrated_device" class="font-weight-bold ml-8">
                                    - {{ $t('information_device_component.integrated_device') }}
                                    <br />
                                </span>
                                <span v-if="device.is_treatment_adblue" class="font-weight-bold ml-8">
                                    - AdBlue
                                    <br />
                                </span>
                            </div>

                            <div class="my-1">
                                <span class="font-color-light">
                                    <v-icon class="mr-1" dense>mdi-calendar-check</v-icon>
                                    {{ $t('information_device_component.version') }} :
                                </span>
                                <br />

                                <span class="font-color-light ml-7"> {{ $t('information_device_component.version_hardware') }} : </span>
                                <span class="font-weight-bold">{{ device.version_hardware }}</span>
                                <br />

                                <span class="font-color-light ml-7"> {{ $t('information_device_component.version_software') }} : </span>
                                <span class="font-weight-bold">{{ device.version_software }}</span>
                                <br />
                            </div>

                            <!--                            <div class="my-1">
                                                            <span class="font-color-light">
                                                                <v-icon class="mr-1" dense>mdi-axis-arrow</v-icon>
                                                                {{ $t('information_device_component.metric') }} :
                                                            </span>
                                                            <span v-for="metric in metrics" :key="metric">
                                                                <v-chip class="mr-1" outlined small>{{ metric }}</v-chip>
                                                            </span><br/>
                                                        </div>-->

                            <v-divider class="my-3" color="white" />

                            <!-- TODO: Just for apple store -> condition to remove -->
                            <div v-if="$store.getters['user_connected/user_connected'].id_user !== 173" class="my-1">
                                <span class="font-color-light">
                                    <v-icon class="mr-1" dense>mdi-credit-card-settings-outline</v-icon>
                                    {{ $t('information_device_component.subscribe') }} :
                                </span>

                                <span v-if="device.contract_paid_by_professional" class="font-weight-bold">
                                    {{ $t('information_device_component.subscribe_handle_by') }}
                                    {{ device.contract.professional.distributor_name }}
                                </span>

                                <template v-else>
                                    <span class="font-weight-bold">
                                        {{
                                            $t('information_device_component.date_from_to', [
                                                moment(device.date_subscription_start).format(date_format),
                                                moment(device.date_subscription_end).format(date_format),
                                            ])
                                        }}
                                    </span>
                                    <br />
                                    <p class="font-italic font-12 ml-7 mb-1 position-relative" style="bottom: 5px">
                                        {{
                                            new Date(device.date_subscription_end) > new Date()
                                                ? $t('information_device_component.subscribe_end_at', [
                                                      moment(device.date_subscription_end).fromNow(),
                                                  ])
                                                : $t('information_device_component.subscribe_end_until', [
                                                      moment(device.date_subscription_end).fromNow(),
                                                  ])
                                        }}
                                    </p>
                                </template>
                            </div>

                            <div class="my-1">
                                <span class="font-color-light">
                                    <v-icon class="mr-1" dense>mdi-calendar-check</v-icon>
                                    {{ $t('information_device_component.date_activation') }} :
                                </span>
                                <br />
                                <span class="font-color-light ml-7">
                                    {{ $t('information_device_component.date_activation_interface') }} :
                                </span>
                                <span class="font-weight-bold">
                                    {{
                                        device.date_activation_interface
                                            ? moment(device.date_activation_interface).format(date_format)
                                            : $t('error_display.no_data')
                                    }}
                                </span>
                                <br />
                                <span class="font-color-light ml-7">
                                    {{ $t('information_device_component.date_activation_device') }} :
                                </span>
                                <span class="font-weight-bold">
                                    {{
                                        device.date_activation_interface
                                            ? moment(device.date_activation_device).format(date_format)
                                            : $t('error_display.no_data')
                                    }}
                                </span>
                                <br />
                            </div>
                        </v-card-text>
                    </div>
                    <img :src="device.path_image_device_referentiel" alt="device" class="image-device" />
                </div>
            </v-expand-transition>
        </v-card>
    </div>
</template>

<script>
import ChipsLastStatement from '@/components/Global/ChipsLastStatement'
import HeaderWidgetComponent from './HeaderWidgetComponent'
import displayHuman from '@/mixin/displayHuman'

export default {
    name: 'InformationDeviceComponent',
    components: {
        ChipsLastStatement,
        HeaderWidgetComponent,
    },
    mixins: [displayHuman],
    props: {
        is_open: {
            type: Boolean,
            required: false,
            default: false,
        },
        device: {
            type: Object,
            required: true,
            default: () => [],
        },
    },
    data() {
        return {
            metrics: [this.$t('information_device_component.level'), this.$t('information_device_component.temperature'), 'GPS'],
        }
    },
}
</script>

<style>
.hidden-information {
    position: relative;
    overflow: hidden;
}

.image-device {
    position: absolute;
    margin-left: auto;
    width: 150px;
    height: 150px;
    top: 10px;
    right: -60px;
    opacity: 0.1;
}
</style>
