<template>
    <div id="asset-model-specifications-widget-component">
        <widget-wrapper-component :title="$t('asset_model_specifications_widget_component.model_specifications')">
            <template v-slot:default>
                <v-fade-transition hide-on-leave>
                    <template v-if="!fetching_model_reference">
                        <div>
                            <template v-if="model_reference?.specifications">
                                <display-json-object-to-list-component :json="JSON.parse(model_reference.specifications)" />
                            </template>

                            <template v-else>
                                {{ $t('asset_model_specifications_widget_component.no_model_associated') }}
                            </template>
                        </div>
                    </template>

                    <template v-else>
                        <v-skeleton-loader type="list-item, list-item, list-item,list-item" />
                    </template>
                </v-fade-transition>
            </template>
        </widget-wrapper-component>
    </div>
</template>

<script>
import WidgetWrapperComponent from '@/components/Equipment/Generic/Widget/WidgetWrapperComponent.vue'
import AssetRepository from '@/repositories/AssetRepository'
import DisplayJsonObjectToListComponent from '@/components/Global/DisplayJsonObjectToListComponent.vue'

export default {
    name: 'AssetModelSpecificationsWidgetComponent',
    components: { DisplayJsonObjectToListComponent, WidgetWrapperComponent },
    props: {
        asset: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            model_reference: {},
            fetching_model_reference: true,
        }
    },
    methods: {
        fetchAssetReference() {
            this.fetching_model_reference = true
            AssetRepository.getAssetReference(this.asset.id_asset)
                .then((success) => {
                    this.model_reference = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.fetching_model_reference = false
                })
        },
    },
    created() {
        this.fetchAssetReference()
    },
}
</script>
