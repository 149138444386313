<template>
    <div id="list-silo-equipment-component" class="pa-4">
        <v-text-field v-model="search" :label="$t('global.search')" class="white-text-field mb-2" filled prepend-inner-icon="mdi-magnify" />
        <p class="font-weight-bold mb-4">Vous avez {{ silos.length }} silos</p>

        <template v-if="!loading_asset">
            <v-row>
                <v-col v-for="silo in silos" :key="silo.id_asset" class="mt-2" cols="12" lg="4" md="6">
                    <silo-card-overview-component :silo="silo" />
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script>
import ClientRepository from '@/repositories/ClientRepository'
import SiloCardOverviewComponent from '@/components/Equipment/Card/SiloCardOverviewComponent.vue'

export default {
    name: 'ListSiloEquipmentComponent',
    components: { SiloCardOverviewComponent },
    data() {
        return {
            loading_asset: true,
            silos: [],
            silos_copy: [],
            search: '',
        }
    },
    created() {
        this.fetchSiloByClient()
    },
    computed: {
        asset_type: {
            get() {
                return this.$store.getters['asset_types/asset_types_which_have_asset'].filter((asset_type) => asset_type.key === 'silo')[0]
            },
        },
    },
    methods: {
        fetchSiloByClient() {
            this.loading_asset = true
            ClientRepository.getUserAssetsInformationByAssetTypeAndIdClient(
                this.$store.getters['user_connected/user_connected'].id_client,
                this.asset_type.id_referential
            )
                .then((success) => {
                    this.silos = success.data.data
                    this.silos_copy = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_asset = false
                })
        },
    },
}
</script>
