<template>
    <div id="chart-by-asset-widget-component">
        <widget-wrapper-component :title="chart_configuration?.label">
            <template v-slot:default>
                <template v-if="is_chart_configuration_complete">
                    <date-range-picker-component
                        :date_range="date_range"
                        :loading="fetching_dtd"
                        class="mb-4"
                        v-on:update="updateRangeDate"
                    />

                    <div v-if="chart_configuration?.series && chart_configuration.series.length > 1" class="d-flex flex-wrap gap-2 mb-4">
                        <template v-for="(serie, index) in chart_configuration.series">
                            <v-checkbox
                                v-if="chart_configuration.y_axis[serie.y_axis].is_visible"
                                :key="index"
                                v-model="is_chart_series_display[index]"
                                :color="chart_series_colors[index]"
                                :label="`${serie.label} ${serie.unity ? `(${serie.unity})` : ''}`"
                                class="mt-0 grow"
                                hide-details
                                @change="fillChartSeries()"
                            />
                        </template>
                    </div>

                    <v-fade-transition hide-on-leave>
                        <template v-if="fetching_dtd">
                            <v-skeleton-loader type="image" />
                        </template>

                        <template v-else>
                            <highcharts :options="options" />
                        </template>
                    </v-fade-transition>
                </template>

                <template v-else>
                    {{ $t('chart_by_asset_widget_component.incomplete_configuration') }}
                </template>
            </template>
        </widget-wrapper-component>
    </div>
</template>

<script>
import WidgetWrapperComponent from '@/components/Equipment/Generic/Widget/WidgetWrapperComponent.vue'
import DateRangePickerComponent from '@/components/Global/DateRangePickerComponent.vue'
import chart from '@/mixin/chart'
import DeviceRepository from '@/repositories/DeviceRepository'

export default {
    name: 'ChartByAssetWidgetComponent',
    components: { DateRangePickerComponent, WidgetWrapperComponent },
    mixins: [chart],
    props: {
        chart_configuration: {
            type: Object,
            required: true,
        },
        device: {
            type: Object,
            required: true,
        },
        asset: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            date_range: {
                startDate: this.moment().clone().startOf('day').subtract(12, 'month').toDate(),
                endDate: this.moment().toDate(),
            },
            chart_options: {},
            fetching_dtd: true,
            is_chart_configuration_complete: false,
        }
    },
    methods: {
        updateRangeDate(date_range) {
            this.date_range = date_range
            this.fetchDeviceTraitedDate()
        },
        fetchDeviceTraitedDate() {
            this.fetching_dtd = true
            DeviceRepository.getHistoricalByDevice(
                this.device.device_number,
                new URLSearchParams({
                    date_start: this.setDateForRequest(this.date_range.startDate),
                    date_end: this.setDateForRequest(this.date_range.endDate),
                    orders: 'date_releve:asc',
                })
            )
                .then((success) => {
                    this.buildDataGraphByAssetMetrics(success.data.data.data)
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.fetching_dtd = false
                })
        },

        buildDataGraphByAssetMetrics(deviceTraitedData) {
            this.resetChartData()
            //Build chart data from configuration
            let tempDtd = [[], [], [], []]

            this.chart_configuration.series.forEach((serie, i) => {
                if (!this.chart_series_data[i]) {
                    this.chart_series_data[i] = []
                    tempDtd[i] = []
                    this.is_chart_series_display.push(true)
                }
            })

            deviceTraitedData?.forEach((dtd, index) => {
                this.chart_configuration.series.forEach((serie, i) => {
                    let config = JSON.parse(serie.parameters).input
                    if (dtd[config[0]] && dtd[config[0]][config[1]] && !isNaN(parseFloat(dtd[config[0]][config[1]][config[2]]))) {
                        if (this.isDayLastEntry(JSON.parse(serie.parameters))) {
                            if (this.isSkip0(JSON.parse(serie.parameters), dtd)) {
                                tempDtd[i][this.moment(dtd.date_releve).format('YYYY-MM-DD')] = [
                                    this.moment(dtd.date_releve).valueOf(),
                                    parseFloat(dtd[config[0]][config[1]][config[2]]) ?? 0,
                                ]
                            }
                        } else {
                            tempDtd[i].push([this.moment(dtd.date_releve).valueOf(), parseFloat(dtd[config[0]][config[1]][config[2]]) ?? 0])
                        }
                    }
                })
            })

            this.chart_configuration.series.forEach((serie, i) => {
                //Fill chart series parameters
                this.chart_series_colors[i] = serie.color

                //Fill chart data
                if (tempDtd[i]) {
                    Object.keys(tempDtd[i]).forEach((key, index) => {
                        this.chart_series_data[i].push([tempDtd[i][key][0], tempDtd[i][key][1]])
                    })
                }
            })

            this.setYAxis(this.chart_configuration.y_axis)
            this.setSeries(this.chart_configuration.series)
            this.setEvents(this.chart_configuration.events)

            this.fillChartSeries()
        },

        isDayLastEntry(parameters) {
            return parameters.days_last_entry !== undefined && parameters.days_last_entry === 1
        },

        isSkip0(parameters, dtd) {
            if (parameters.skip_0 !== undefined && parameters.skip_0 === 1) {
                let config = parameters.input
                return dtd[config[0]][config[1]][config[2]] !== null
            }

            return true
        },
    },
    created() {
        if (this.device && this.chart_configuration) {
            this.fetchDeviceTraitedDate()
            this.is_chart_configuration_complete = true
        }
    },
}
</script>
