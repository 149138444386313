<template>
    <div id="display-json-object-to-list-component">
        <v-list>
            <template v-for="key in Object.keys(json)">
                <template v-if="typeof json[key] === 'object'">
                    <v-list-group :key="key">
                        <template v-slot:activator>
                            <v-list-item>
                                <div :style="`margin-left: ${depth * 12}px`">
                                    <span class="font-weight-bold text-uppercase">{{ key }}</span>
                                </div>
                            </v-list-item>
                        </template>

                        <display-json-object-to-list-component :depth="depth + 1" :json="json[key]" />
                    </v-list-group>
                </template>

                <template v-else>
                    <v-list-item :key="key">
                        <div :style="`margin-left: ${depth * 12}px`" class="pl-4">
                            <span class="mr-1 font-color-medium text-capitalize">{{ key }}:</span>
                            <span class="font-weight-bold font-color-normal">{{ json[key] }}</span>
                        </div>
                    </v-list-item>
                </template>
            </template>
        </v-list>
    </div>
</template>

<script>
export default {
    name: 'DisplayJsonObjectToListComponent',
    props: {
        json: {
            type: Object,
            required: true,
        },
        depth: {
            type: Number,
            required: false,
            default: 0
        },
    },
}
</script>