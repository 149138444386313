<template>
    <div>
        <widget-wrapper-component :title="widget_title !== '' ? widget_title : $t('donut_chart_widget_component.donut_chart')">
            <template v-if="!fetching_donut_data">
                <highcharts :options="options" />
            </template>

            <template v-else>
                <div class="text-center">
                    <v-progress-circular indeterminate size="380" width="16" />
                </div>
            </template>
        </widget-wrapper-component>
    </div>
</template>

<script>
import WidgetWrapperComponent from '@/components/Equipment/Generic/Widget/WidgetWrapperComponent.vue'
import ModuleRepository from '@/repositories/ModuleRepository'

export default {
    name: 'DonutChartWidgetComponent',
    components: { WidgetWrapperComponent },
    props: {
        device: {
            type: Object,
            required: true,
        },
        asset_module: {
            type: Object,
            required: true,
        },
        configuration_widget: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            options: {
                chart: {
                    type: 'pie',
                },
                exporting: {
                    accessibility: {
                        enabled: true,
                    },
                    menuItemDefinitions: {
                        downloadPNG: {
                            text: 'Télécharger au format PNG',
                        },
                        downloadPDF: {
                            text: 'Télécharger au format PDF',
                        },
                    },
                    buttons: {
                        contextButton: {
                            menuItems: ['downloadPNG', 'downloadPDF'],
                        },
                    },
                    enabled: true,
                    filename: 'text_filename',
                },
                title: {
                    text: '',
                    align: 'left',
                },
                subtitle: {
                    useHTML: true,
                    text: ``,
                    floating: true,
                    verticalAlign: 'middle',
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: false,
                        cursor: 'pointer',
                        innerSize: '75%',
                        dataLabels: {
                            enabled: true,
                            format: `{point.name}<br> {y}`,
                            className: 'font-18',
                        },
                    },
                },
                colors: [],
                series: [
                    {
                        name: '',
                        colorByPoint: true,
                        data: [],
                    },
                ],
            },

            widget_title: '',
            fetching_donut_data: true,
        }
    },
    created() {
        this.postModuleDonut()
    },
    methods: {
        postModuleDonut() {
            this.fetching_donut_data = true
            ModuleRepository.postModuleDonut(this.asset_module.id_module, this.device.id_device, this.configuration_widget)
                .then((success) => {
                    const series = []
                    success.data.data.series.forEach((serie, index) => {
                        // Find metric associate to get unit
                        this.asset_module.default_metrics_configuration.metrics.forEach((metric) => {
                            if (
                                JSON.stringify(JSON.parse(metric.parameters).output) ===
                                JSON.stringify(this.configuration_widget.series[index].path)
                            ) {
                                this.options.plotOptions.pie.dataLabels.format = `{point.name}<br> {y} ${metric.unit}`
                            }
                        })

                        series.push({
                            name: serie.label,
                            y: serie.value ?? 0,
                        })

                        this.options.colors.push(this.configuration_widget.series[index].color)

                        if (this.configuration_widget.display_percentage && !!this.configuration_widget.series[index]?.is_main_metric) {
                            this.options.subtitle.text = `<span class="font-color-normal font-weight-bold font-18">${serie.percent} %</span>`
                        }
                    })

                    this.options.series[0].data = series
                    this.widget_title = success.data.data.title
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.fetching_donut_data = false
                })
        },
    },
}
</script>
