<template>
    <div id="chart-by-metrics-widget-component">
        <widget-wrapper-component :title="$t('chart_by_metrics_widget_component.consumption_chart')">
            <template v-slot:default>
                <date-range-picker-component :date_range="date_range" :loading="fetching_dtd" class="mb-4" v-on:update="updateRangeDate" />

                <template v-if="fetching_dtd">
                    <v-skeleton-loader type="image" />
                </template>

                <template v-else>
                    <div v-if="metrics" class="d-flex flex-wrap gap-2 mb-4">
                        <template v-for="(metric, index) in metrics">
                            <v-checkbox
                                :key="index"
                                v-model="is_chart_series_display[index]"
                                :color="chart_series_colors[index]"
                                :label="metric.label"
                                class="mt-0 grow"
                                hide-details
                                @change="fillChartSeries()"
                            />
                        </template>
                    </div>

                    <highcharts :options="options" />
                </template>
            </template>
        </widget-wrapper-component>
    </div>
</template>

<script>
import WidgetWrapperComponent from '@/components/Equipment/Generic/Widget/WidgetWrapperComponent.vue'
import chart from '@/mixin/chart'
import DateRangePickerComponent from '@/components/Global/DateRangePickerComponent.vue'
import DeviceRepository from '@/repositories/DeviceRepository'

export default {
    name: 'ChartByMetricsWidgetComponent',
    components: { DateRangePickerComponent, WidgetWrapperComponent },
    mixins: [chart],
    props: {
        asset: {
            type: Object,
            required: true,
        },
        device: {
            type: Object,
            required: true,
        },
        asset_module: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            date_range: {
                startDate: this.moment().clone().startOf('day').subtract(12, 'month').toDate(),
                endDate: this.moment().toDate(),
            },

            metrics: [],
            fetching_dtd: true,
        }
    },
    methods: {
        getChartSeriesColors() {
            if (this.asset.configuration && this.$store.getters['asset_module/liquids_by_asset_module'](this.asset_module.id_module).length > 0) {
                let configurations = JSON.parse(this.asset.configuration)

                configurations.forEach((configuration, index) => {
                    if (configuration.is_required) {
                        this.chart_series_colors[index] = this.$store.getters[
                            'asset_module/liquid_by_id_module_and_id_asset_and_model'
                        ](this.asset_module.id_module, this.asset.id_asset, index).color
                    }
                })
            } else if (this.asset.asset_liquid) {
                this.chart_series_colors[0] = this.asset.asset_liquid.color ?? this.$store.getters['display_option/display_option'].primary
            }
        },

        updateRangeDate(date_range) {
            this.date_range = date_range
            this.fetchDeviceTraitedDate()
        },

        fetchDeviceTraitedDate() {
            this.fetching_dtd = true
            DeviceRepository.getHistoricalByDevice(
                this.device.device_number,
                new URLSearchParams({
                    date_start: this.setDateForRequest(this.date_range.startDate),
                    date_end: this.setDateForRequest(this.date_range.endDate),
                    orders: 'date_releve:asc',
                })
            )
                .then((success) => {
                    this.buildDataGraphByMetrics(success.data.data.data)
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.fetching_dtd = false
                })
        },

        buildMetricsToDisplay() {
            this.device.metrics.slice(0, 4).forEach((metric) => {
                this.metrics.push(metric.metric)
            })
        },

        buildDataGraphByMetrics(device_traited_data) {
            this.resetChartData()

            let series = []
            for (let i = 0; i < 4; i++) {
                device_traited_data.forEach((dtd) => {
                    this.chart_series_data[i].push([
                        this.moment(dtd.date_releve).valueOf(),
                        parseFloat(dtd[this.metrics[i].column_name_dtd]),
                    ])
                })

                series.push({
                    label: `${this.metrics[i].label} (${this.metrics[i].unit})`,
                    parameters: '',
                    unity: this.metrics[i].unit,
                    chart_type: 'line',
                    color: this.chart_series_colors[i],
                    y_axis: 0,
                })
            }

            this.setSeries(series)
            this.fillChartSeries()
        },
    },
    created() {
        this.buildMetricsToDisplay()
        this.getChartSeriesColors()
        this.fetchDeviceTraitedDate()
    },
}
</script>
