<template>
    <div id="custom-icon-component" class="display-flex-align-center">
        <template v-if="isCustomIcon">
            <i :class="`${validIcon}`" />
        </template>

        <template v-else>
            <v-icon :color="color">
                {{ validIcon }}
            </v-icon>
        </template>
    </div>
</template>

<script>
export default {
    name: 'CustomIconComponent',
    props: {
        icon: {
            type: String,
            required: true,
        },
        default_icon: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: false
        }
    },
    computed: {
        isCustomIcon() {
            return this.icon && (this.icon.includes('fourdata'))
        },

        validIcon() {
            if(this.icon && this.icon !== '' && (this.icon.includes('fourdata') || this.icon.includes('mdi'))) {
                return this.icon
            }

            return this.default_icon
        }
    },
}
</script>